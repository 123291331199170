import {
  ArrowRightToLine,
  MoreVertical,
  NotebookPen,
  SlidersVertical,
} from "lucide-react";
import { useContext, useState, useMemo } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
  JOB_OPPORTUNITY_STATUSES,
} from "lookup";
import {
  JobsContext,
  SearchContext,
  ModalContext,
  MatchContext,
} from "context/providers";
import SvgIcon from "components/base/SvgIcon";
import { getButtonText } from "components/UserCardList/helpers/userCard";

function HitActionDropDown({ row, collectionKey }) {
  const { showModal } = useContext(ModalContext);
  const { jobOpp } = useContext(JobsContext);
  const { hitsMatchAnalysis } = useContext(SearchContext);
  const { updateMatch, createMatch } = useContext(MatchContext);

  const hit = row.original;

  const hasPrimaryMatcher = !!jobOpp.torcOwner?.username;
  const isNotCalibration = !hit.associatedMatch?.isCalibration;
  const isJobStatusActive = jobOpp.status === JOB_OPPORTUNITY_STATUSES.ACTIVE;

  const [isLoading, setIsLoading] = useState({
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
    [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.NULL]: false,
    [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]: false,
    [JOB_APPLICATION_MATCH_STATUS.APPLIED]: false,
    isCalibration: false,
  });

  const isPreShortListed =
    hit.associatedMatch?.status === JOB_APPLICATION_MATCH_STATUS.PRESHORTLISTED;

  const noCalibrationStatuses = [
    JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
    JOB_APPLICATION_MATCH_STATUS.SKIPPED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
  ];

  const canBeCalibration = !noCalibrationStatuses.includes(
    hit.associatedMatch?.status
  );

  const oneOfButtonsLoading = useMemo(() => {
    return !!Object.values(isLoading).filter((el) => !!el).length;
  }, [isLoading]);

  const { applicationId, jobOpportunityId } = useMemo(() => {
    const app = hit.applications?.find(
      (a) => a.jobTypeId === jobOpp.jobType?.id
    );

    return {
      applicationId:
        hit?.match?.applicationId ||
        hit?.associatedMatch?.applicationId ||
        app?.id ||
        `User#${hit.id}`,
      jobOpportunityId: jobOpp?.id,
    };
  }, [hit, jobOpp]);

  const matchAction = async (args, isUpdate) => {
    let attrs = {};

    if (hit.associatedMatch) {
      if (hit.associatedMatch.localCustomerSummary) {
        attrs.customerSummary = hit.associatedMatch.localCustomerSummary;
      }

      if (hit.associatedMatch.localAnalysis) {
        attrs.analysis = { ...hit.associatedMatch.localAnalysis };
      }
    } else if (hitsMatchAnalysis[hit?.id]) {
      attrs = {
        ...attrs,
        ...hitsMatchAnalysis[hit?.id],
      };
    }

    if (args.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) {
      // passing the value to the modal so it can be viewed in the modal
      if (hit.associatedMatch) {
        if (
          hit.associatedMatch.customerSummary ||
          hit.associatedMatch.localCustomerSummary
        ) {
          attrs.customerSummary =
            hit.associatedMatch.customerSummary ||
            hit.associatedMatch.localCustomerSummary;
        }

        if (hit.associatedMatch.analysis || hit.associatedMatch.localAnalysis) {
          attrs.analysis = {
            score:
              hit.associatedMatch?.analysis?.score ??
              hit.associatedMatch?.localAnalysis?.score,
            report:
              hit.associatedMatch?.analysis?.report ||
              hit.associatedMatch?.localAnalysis?.report,
          };
        }
      }

      showModal({
        type: "match",
        applicationId,
        jobOpportunityId,
        userId: hit.id,
        userIsUnclaimed: hit.isUnclaimed,
        collectionKey,
        status: args.status,
        isUpdate,
        args: { ...attrs },
      });
      return;
    }

    const matchAttrs = { ...args, ...attrs };

    if (args.isCalibration) {
      showModal({
        type: "calibration",
        applicationId,
        jobOpportunityId,
        userId: hit.id,
        userIsUnclaimed: hit.isUnclaimed,
        collectionKey,
        status: args.status,
        isCalibration: args.isCalibration,
        isUpdate,
        args: { ...attrs },
      });
      return;
    }
    setIsLoading((prev) => ({ ...prev, [args.status]: true }));

    if (hit.isUnclaimed) {
      args.unclaimedWhenMatched = true;
    }

    try {
      if (isUpdate) {
        await updateMatch(applicationId, jobOpportunityId, matchAttrs);
      } else {
        await createMatch(applicationId, jobOpportunityId, hit.id, matchAttrs);
      }
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [args.status]: false }));
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open action menu</span>
          <MoreVertical className="h-5 w-5 text-gray-500" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {canBeCalibration && isNotCalibration && (
          <DropdownMenuItem
            onClick={() =>
              matchAction(
                !!hit.associatedMatch
                  ? {
                      isCalibration: true,
                    }
                  : {
                      status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
                      isCalibration: true,
                    },
                !!hit.associatedMatch
              )
            }
            disabled={oneOfButtonsLoading}
            className={
              isNotCalibration ? "" : "hover:!fill-red-500 hover:text-red-500"
            }
          >
            <div className="h-5 w-5">
              <SlidersVertical size={20} />
            </div>
            {getButtonText(
              isNotCalibration ? "calibration" : "removeCalibration"
            )}
          </DropdownMenuItem>
        )}

        {(!hit.associatedMatch || isPreShortListed) && (
          <>
            <DropdownMenuItem
              disabled={
                oneOfButtonsLoading || !isJobStatusActive || !hasPrimaryMatcher
              }
              onClick={() => {
                if (!hit.hasActivePlacements) {
                  matchAction(
                    { status: JOB_APPLICATION_MATCH_STATUS.MATCHED },
                    isPreShortListed
                  );
                } else if (
                  window.confirm(
                    "Are you sure you want to match this user, even though they have active engagement(s) currently?"
                  )
                )
                  matchAction(
                    { status: JOB_APPLICATION_MATCH_STATUS.MATCHED },
                    isPreShortListed
                  );
              }}
              type="match"
            >
              <div className="grid place-items-center h-5 w-5">
                <SvgIcon
                  type={hit.hasActivePlacements ? "warning" : "heart"}
                  className={"!w-full"}
                />
              </div>
              {getButtonText("match")}
            </DropdownMenuItem>

            <DropdownMenuItem
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction(
                  {
                    status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
                  },
                  isPreShortListed
                )
              }
            >
              <div className="grid place-items-center h-5 w-5">
                <SvgIcon type="focus" className="w-[20px] h-[20px]" />
              </div>
              {getButtonText("shortlist")}
            </DropdownMenuItem>

            <DropdownMenuItem
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction(
                  { status: JOB_APPLICATION_MATCH_STATUS.SKIPPED },
                  isPreShortListed
                )
              }
            >
              <div className="grid place-items-center h-5 w-5">
                <ArrowRightToLine size={20} />
              </div>

              {getButtonText("skip")}
            </DropdownMenuItem>
          </>
        )}

        <DropdownMenuItem
          onClick={() =>
            showModal({
              type: "note",
              title: `Match Notes: ${row.original.username}`,
              applicationId,
              isAllowedPublicNote: true,
              showGenerateAnalysisBasedOnNote: true,
            })
          }
        >
          <div className="grid place-items-center h-5 w-5">
            <NotebookPen size={20} />
          </div>
          Notes{!!hit.notesCount ? ` (${hit.notesCount})` : ""}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default HitActionDropDown;
