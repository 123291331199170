import { cloneDeep, sortBy } from "lodash";
import dayjs from "dayjs";

import { JOB_OPPORTUNITY_STATUSES } from "lookup";
import api from "apiSingleton";
import { initialState } from "context/reducers/jobs";

const ITEM_LIMIT = 500;

function sortByStatus(jobs) {
  const jobStatusesOrder = [
    JOB_OPPORTUNITY_STATUSES.ACTIVE,
    JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL,
    JOB_OPPORTUNITY_STATUSES.FULFILLED,
    JOB_OPPORTUNITY_STATUSES.RDFULFILLED,
    JOB_OPPORTUNITY_STATUSES.CANCELLED,
    JOB_OPPORTUNITY_STATUSES.DRAFT,
  ];

  return sortBy(jobs, [
    function (job) {
      return jobStatusesOrder.indexOf(job.status);
    },
    jobs.sort((a, b) =>
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
    ),
  ]);
}

const mapMatches = (matches) => {
  if (matches?.items) {
    return matches.items.map((el) => ({
      ...el,
      isMatching: false,
      isShortlisting: false,
      isDeleting: false,
    }));
  }

  return [];
};

const loadJobOpportunities = async (nextToken = null, filters) => {
  if (filters.length === 0) {
    return [];
  }

  const response = await api.jobs.list({
    filter: {
      status: {
        in: filters,
      },
    },
    limit: ITEM_LIMIT,
    nextToken,
  });

  const jobs = response.data.listJobOpportunitys;

  if (jobs.nextToken) {
    await new Promise((resolve) => setTimeout(resolve, 250));
    const jobsNextPortions = await loadJobOpportunities(
      jobs.nextToken,
      filters
    );

    return [...jobs.items, ...jobsNextPortions];
  }

  return jobs.items;
};

export async function initializeJobs(filters = initialState.statusFilter) {
  const jobs = await loadJobOpportunities(null, filters);

  const jobsByStatus = sortByStatus(jobs);
  const companyNames = {};
  const jobTypes = {};
  const jobSkills = {};
  const jobOptionalSkills = {};
  const torcOwners = {};

  const jobOpps = [
    ...jobsByStatus?.map((el) => ({
      ...el,
      matches: { items: mapMatches(el.matches) },
    })),
  ];

  for (let job of jobOpps) {
    const organizationName = job?.organization;
    const jobType = job?.jobType?.title;

    const skills = job?.skills || [];

    const optionalSkills = job?.optionalSkills || [];

    if (organizationName && !companyNames[organizationName]) {
      companyNames[organizationName] = organizationName;
    }

    if (job?.torcOwner && !torcOwners[job.torcOwner.username]) {
      torcOwners[job.torcOwner.username] = job.torcOwner;
    }

    if (jobType && !jobTypes[jobType]) {
      jobTypes[jobType] = true;
    }

    if (skills.length > 0) {
      skills.forEach((skill) => {
        if (!jobSkills[skill]) {
          jobSkills[skill.name] = skill.name;
        }
      });
    }

    if (optionalSkills.length > 0) {
      optionalSkills.forEach((skill) => {
        if (!jobOptionalSkills[skill]) {
          jobOptionalSkills[skill.name] = skill.name;
        }
      });
    }
  }

  return {
    jobOpps,
    companyNames,
    jobTypes,
    jobSkills,
    torcOwners,
    jobOptionalSkills,
  };
}

const removeErrorProperty = (data, path, message) => {
  if (!data || path.length === 0) return data;

  const [current, ...restPath] = path;

  if (data[current] && (current === "user" || data[current]?.applicationId)) {
    data[current].apiErrors = [
      ...(data?.[current]?.apiErrors || []),
      {
        path,
        message,
      },
    ];
  }

  if (!(current in data)) return data;

  if (restPath.length === 1) {
    delete data[current];

    if (Array.isArray(data)) {
      data = data.filter((item) => item);
    }
  } else {
    data[current] = removeErrorProperty(data[current], restPath, message);
  }

  return data;
};

export async function initializeJob(id, { addGraphQLAlert, addCustomAlert }) {
  let jobDetails;

  let hasErrors = false;

  try {
    const job = await api.jobs.get({ id });
    jobDetails = job.data.getJobOpportunity;
  } catch (err) {
    if (
      !err.errors ||
      !err.errors.some(
        ({ path }) =>
          path && (path.includes("user") || path.includes("matches"))
      )
    ) {
      throw err;
    }

    hasErrors = true;

    jobDetails = err?.data?.getJobOpportunity ?? {};

    err.errors.forEach(({ path, message }) => {
      if (path) {
        path.shift();
        removeErrorProperty(jobDetails, path, message);
      }
    });

    addGraphQLAlert(err, undefined, 35000);
  }

  let nextToken = jobDetails?.matches?.nextToken;

  const nextJobDetailsMatches = { matches: { items: [] } };

  while (nextToken) {
    try {
      const getNextMatches = await api.jobs.getNextMatches({ id, nextToken });
      const matches =
        getNextMatches?.data?.getJobOpportunity?.matches?.items || [];
      const currentMatches = nextJobDetailsMatches?.matches?.items;
      if (currentMatches) {
        nextJobDetailsMatches.matches.items = [...currentMatches, ...matches];
      }

      nextToken = getNextMatches?.data?.getJobOpportunity?.matches?.nextToken;
    } catch (err) {
      addGraphQLAlert(err, undefined, 35000);

      if (
        !err.errors ||
        !err.errors.some(
          ({ path }) =>
            path && (path.includes("user") || path.includes("matches"))
        )
      ) {
        throw err;
      }

      hasErrors = true;

      const errorResponse = err?.data?.getJobOpportunity ?? {};

      err.errors.forEach(({ path, message }) => {
        if (path) {
          path.shift();
          removeErrorProperty(errorResponse, path, message);
        }
      });

      const matches = errorResponse?.matches?.items || [];

      const currentMatches = nextJobDetailsMatches?.matches?.items;
      if (currentMatches && matches) {
        nextJobDetailsMatches.matches.items = [...currentMatches, ...matches];
      }

      nextToken = err?.data?.getJobOpportunity?.matches?.nextToken;
    }
  }

  jobDetails.matches.items = [
    ...jobDetails.matches.items,
    ...nextJobDetailsMatches.matches.items,
  ];

  if (hasErrors === true) {
    addCustomAlert({
      title: "Error contact support",
      duration: 20000,
      message:
        "There was an issue with the fetched data please contact administrator for support",
    });
  }

  jobDetails.matches?.items.forEach((item) => {
    if (item?.user?.id) {
      const user = item.user;
      // Similar logic in place in algolia processor - so any updates here must reflect there
      // and vice versa
      if (user.placements?.length > 0) {
        for (const placement of user.placements) {
          const placementEndDate = placement.actualEndDate ?? placement.endDate;
          placement.endDateTimeStamp = Math.floor(
            new Date(placementEndDate).getTime() / 1000
          );
        }

        const nowTimeStamp = Math.floor(new Date().getTime() / 1000);

        user.hasActivePlacements = user.placements.some(
          (placement) => nowTimeStamp < placement.endDateTimeStamp
        );

        user.hasPlacements = true;
      }

      const resumeParagraphs = user.documentsText?.items?.find(
        (e) => e.type === "RESUME"
      )?.paragraphs;

      user.parsedResume = JSON.parse(resumeParagraphs || "[]");
    }
  });

  return jobDetails;
}

export async function initializeJobCalendarEvents(id) {
  try {
    const { data } = await api.jobs.getEvents({ id });
    return data.getJobOpportunityEvents;
  } catch (error) {
    console.log("Something went wrong when fetching job calendar events");
    return [];
  }
}

export function updateJobLocally(job, value, valueKey) {
  const clonedJob = cloneDeep(job);
  const updatedJob = { ...clonedJob, [valueKey]: value };

  return updatedJob;
}

export async function updateJob(id, payload) {
  const job = await api.jobs.update({
    input: {
      id,
      ...payload,
    },
  });

  return job;
}
