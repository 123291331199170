import React, { useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import {
  RefinementList,
  ToggleRefinement,
  NumericMenu,
  DynamicWidgets,
  Panel,
  connectRefinementList,
  SortBy,
} from "react-instantsearch-dom";

import { JobsContext, SearchContext } from "context/providers";

import {
  DEFAULT_BH_STATUSES_TO_EXCLUDE,
  DEFAULT_RATE_RANGE,
  EDUCATION_DEGREE_TYPES,
  EDUCATION_DEGREE_TYPES_TEXT,
  LANGUAGE_KEY_NAMES,
  USER_TYPES,
  YEARS_OF_EXPERIENCE_CONFIG,
  YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED,
} from "lookup";

import {
  CustomPanel,
  CustomRangeSlider,
  CustomRefinementList,
  // PlacementsRefinementWrapper,  // replaced by BHStatusFilterWrapper (bullhornStatus)
} from "./components";
import LocationFiltersWrapper from "components/LocationsFilterWrapper";
import SkillsFilterWrapper from "components/SkillFilterWrapper";
import YearsOfExperience from "./components/YearsOfExperience";
import BHStatusFilterWrapper from "./components/BHStatusFilterWrapper";

const subtractUnix = (amount, unit) =>
  dayjs().subtract(amount, unit).unix() * 1000;

const CREATED_AT_ITEMS = [
  {
    label: "< 1 day",
    start: subtractUnix(1, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 7 days",
    start: subtractUnix(6, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 30 days",
    start: subtractUnix(29, "days"),
    end: dayjs().unix() * 1000,
  },
];

const LAST_ACTIVITY_ITEMS = [
  {
    label: "< 1 day",
    start: subtractUnix(1, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 7 days",
    start: subtractUnix(6, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 30 days",
    start: subtractUnix(29, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 60 days",
    start: subtractUnix(59, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "> 60 days",
    end: dayjs().unix() * 1000,
  },
];

const isCountryAllowedForYearsOfExperience = (countryName) => {
  if (!YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED) {
    return false;
  }

  return YEARS_OF_EXPERIENCE_CONFIG.locationCountryNames.includes(countryName);
};

const VirtualRefinementList = connectRefinementList(() => null);

const SearchFilters = () => {
  const { searchState, operators, setOperators, geographicalRegions } =
    useContext(SearchContext);
  const { jobOpp } = useContext(JobsContext);

  const [showRequiredExperience, setShowRequiredExperience] = useState(false);

  const mapToFacetString = (firstAttributes, secondAttributes) => {
    const output = [];

    firstAttributes.forEach((firstAttribute) => {
      secondAttributes.forEach((secondAttribute) => {
        output.push(`${firstAttribute}_${secondAttribute}`);
      });
    });

    return output;
  };

  const languagesStr = useMemo(() => {
    const languages =
      searchState.refinementList?.[LANGUAGE_KEY_NAMES.language] || [];
    const languagesLevel =
      searchState.refinementList?.[LANGUAGE_KEY_NAMES.level] || [];

    return mapToFacetString(languages, languagesLevel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.refinementList]);

  const definedRate = useMemo(
    () => searchState?.range?.["ratePerHour.value"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState?.range?.["ratePerHour.value"]]
  );

  const handleChange = (value, valueKey, targetName) => {
    switch (targetName) {
      case "operators": {
        setOperators(valueKey);
        break;
      }

      default: {
        break;
      }
    }
  };

  useEffect(() => {
    if (jobOpp?.location?.countryName) {
      // Country should be in the whitelist
      if (isCountryAllowedForYearsOfExperience(jobOpp.location?.countryName)) {
        setShowRequiredExperience(true);
      } else {
        setShowRequiredExperience(false);
      }
    } else if (jobOpp?.geographicalRegions?.length > 0) {
      // ALL countries should be in the whitelist
      const countries = jobOpp.geographicalRegions
        .map((grOuter) => {
          if (grOuter.countryNames.length === 0) {
            const matchingRegion = geographicalRegions?.find(
              (grInner) => grInner.regionName === grOuter.regionName
            );
            return matchingRegion?.countryNames || [];
          }

          return [...grOuter.countryNames];
        })
        .flat();

      if (countries.every(isCountryAllowedForYearsOfExperience)) {
        setShowRequiredExperience(true);
      } else {
        setShowRequiredExperience(false);
      }
    } else {
      setShowRequiredExperience(false);
    }
  }, [jobOpp, geographicalRegions]);

  return (
    <>
      <div id="filter-group-sort-by">
        <Panel header="sort by">
          <SortBy
            defaultRefinement={process.env.REACT_APP_INDEX_NAME}
            items={[
              {
                value: process.env.REACT_APP_INDEX_NAME,
                label: "Default",
              },
              {
                value: `torcQualityScore_desc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Match Ready (desc)",
              },
              {
                value: `createdAt_dt_desc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Date Joined (desc)",
              },
              {
                value: `createdAt_dt_asc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Date Joined (asc)",
              },
              {
                value: `profileCompletion_desc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Profile Completion (desc)",
              },
              {
                value: `profileCompletion_asc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Profile Completion (asc)",
              },
              {
                value: `rate_desc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Rate (desc)",
              },
              {
                value: `rate_asc-${process.env.REACT_APP_INDEX_NAME}`,
                label: "Rate (asc)",
              },
            ]}
          />
        </Panel>
      </div>
      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <SkillsFilterWrapper
          operatorChange={handleChange}
          attribute={"skills.name"}
        />
        <VirtualRefinementList attribute="skills_str" />
      </DynamicWidgets>
      <div id="filter-group-location">
        <LocationFiltersWrapper />
        <VirtualRefinementList attribute="location.stateName" />
        <VirtualRefinementList attribute="location.cityName" />
      </div>
      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <div id="filter-group-hourly-rate">
          <Panel header="Hourly Rate">
            <CustomRangeSlider
              attribute="ratePerHour.value"
              {...(definedRate?.min && { min: DEFAULT_RATE_RANGE.min })}
              {...(definedRate?.max && { max: DEFAULT_RATE_RANGE.max })}
              displayMin={DEFAULT_RATE_RANGE.min}
              displayMax={DEFAULT_RATE_RANGE.max}
              showInputs
            />
          </Panel>
        </div>
        <CustomPanel
          id="filter-group-job-role"
          title="Job Role"
          checked={operators["activeJobTypeTitles"] === "and"}
          valueKey="activeJobTypeTitles"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="Select OR if you want to see talent with EITHER of the job roles. Select AND if you would like to only see talent with BOTH of the job roles."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <RefinementList
            attribute="activeJobTypeTitles"
            translations={{
              placeholder: "Search for a specific job role",
            }}
            operator={operators["activeJobTypeTitles"]}
            limit={10}
            showMore
          />
        </CustomPanel>
        <div id="filter-group-availability">
          <Panel header="Availability">
            <RefinementList attribute="availability" />
          </Panel>
        </div>
      </DynamicWidgets>
      <div id="filter-group-notice-period">
        <Panel header="Notice Period">
          <NumericMenu
            attribute="noticePeriod"
            items={[
              { label: "Immediate", start: 0, end: 0 },
              { label: "<= 15 Days", end: 15 },
              { label: "<= 30 Days", end: 30 },
              { label: "<= 60 Days", end: 60 },
              { label: "<= 90 Days", end: 90 },
            ]}
            translations={{
              all: "All",
            }}
            transformItems={(items) =>
              items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              })
            }
          />
        </Panel>
      </div>
      {showRequiredExperience && <YearsOfExperience />}
      <div id="filter-group-work-authorization">
        <Panel header="Work Authorization">
          <RefinementList attribute="workAuthorizations" />
        </Panel>
      </div>
      <div id="filter-group-bh-status">
        <BHStatusFilterWrapper
          isReadOnly={jobOpp?.id && DEFAULT_BH_STATUSES_TO_EXCLUDE?.length > 0}
        />
      </div>

      {/*
      // replaced by BHStatusFilterWrapper (bullhornStatus)
      // only commenting out since will be used again in the future.
      //<PlacementsRefinementWrapper />
      */}

      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <CustomPanel
          id="filter-group-test-name"
          title="Test Name"
          checked={operators["assessments.testName"] === "and"}
          valueKey="assessments.testName"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple tests. Ex:  C# and Java. Select OR if you want to see talent with either of the test. Select AND if you would like to see the only talents with both the tests."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="assessments.testName"
            placeholder="Search for tests"
            operator={operators["assessments.testName"]}
            limit={15}
            showCount
            searchable
            showMore
            showChip
          />
        </CustomPanel>
        <div id="filter-group-final-score">
          <Panel header="Assessments Final Score">
            <NumericMenu
              attribute="assessments.finalScore"
              items={[
                { label: "<= 25", start: 0, end: 25 },
                { label: "26 <= 50", start: 26, end: 50 },
                { label: "51 <= 75", start: 51, end: 75 },
                { label: ">= 76", start: 76, end: 100 },
                { label: "= 100", start: 100, end: 100 },
              ]}
              transformItems={(items) =>
                items.map((item) => {
                  return {
                    ...item,
                    noRefinement: false,
                  };
                })
              }
            />
          </Panel>
        </div>
      </DynamicWidgets>
      <CustomPanel
        id="filter-group-education-records"
        title="Educaton"
        checked={operators["educationRecords.degreeType"] === "and"}
        valueKey="educationRecords.degreeType"
        leftToggleLabel="OR"
        rightToggleLabel="AND"
        helpText="If you would like to filter multiple degree types. Ex:  Bachelors and Masters. Select OR if you want to see talent with either of the degree types. Select AND if you would like to see the only talents with both the degree types."
        onChange={(value, valueKey) =>
          handleChange(value, valueKey, "operators")
        }
        toggle
      >
        <RefinementList
          attribute="educationRecords.degreeType"
          placeholder="Search for degree type"
          operator={operators["educationRecords.degreeType"]}
          limit={15}
          searchable
          showMore
          transformItems={(items) =>
            items
              .filter(
                (e) =>
                  e.label !== EDUCATION_DEGREE_TYPES.DEGREE_TYPE_UNSPECIFIED
              )
              .map((item) => ({
                ...item,
                label: EDUCATION_DEGREE_TYPES_TEXT[item.label] ?? item.label,
              }))
          }
        />
      </CustomPanel>
      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <CustomPanel
          id="filter-group-language"
          title="Language"
          checked={operators["knownLanguages.language"] === "and"}
          valueKey="knownLanguages.language"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple languages. Ex:  English and Spanish. Select OR if you want to see talent with either of the language. Select AND if you would like to see the only talents with both the language."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="knownLanguages.language"
            translations={{
              placeholder: "Search for languages",
            }}
            operator={operators["knownLanguages.language"]}
            limit={5}
            showMoreLimit={1000}
            showCount
            showMore
            searchable
          />
        </CustomPanel>
        <Panel header="Language Level">
          <CustomRefinementList
            attribute="knownLanguages.level"
            placeholder="Search for language level"
            showCount={false}
          />
        </Panel>
        <VirtualRefinementList
          attribute="knownLanguages_str"
          defaultRefinement={languagesStr}
        />

        <CustomPanel
          id="filter-group-community"
          title="Community"
          checked={operators["cognitoGroups"] === "and"}
          valueKey="cognitoGroups"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="Select OR if you want to see talent within ANY of these groups. Select AND if you would like to only see talent within ALL of the selected groups."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <RefinementList
            attribute="cognitoGroups"
            operator={operators["cognitoGroups"]}
          />
        </CustomPanel>
        <div id="filter-group-talent-source">
          <Panel header="Talent source">
            <RefinementList attribute="talentSource" />
          </Panel>
        </div>
        <div id="filter-group-referrer-code">
          <Panel header="Referrer Code">
            <RefinementList
              attribute="referrerCode"
              translations={{
                placeholder: "Search for specific referrer code",
              }}
              limit={5}
              searchable
              showMore
            />
          </Panel>
        </div>
        <div id="filter-group-profile-completion">
          <Panel header="% of Profile Completion">
            <NumericMenu
              attribute="profileCompletion"
              items={[
                { label: "<= 25", start: 0, end: 25 },
                { label: "26 <= 50", start: 26, end: 50 },
                { label: "51 <= 75", start: 51, end: 75 },
                { label: ">= 76", start: 76, end: 100 },
                { label: "= 100", start: 100, end: 100 },
              ]}
              transformItems={(items) =>
                items.map((item) => {
                  return {
                    ...item,
                    noRefinement: false,
                  };
                })
              }
            />
          </Panel>
        </div>
        <div id="filter-group-referrer-source">
          <Panel header="Referrer Source">
            <RefinementList
              attribute="referrerSource"
              items={[{ label: "Recruiter resume", value: "RECRUITERRESUME" }]}
              transformItems={(items) =>
                items.map((item) => {
                  return {
                    ...item,
                    noRefinement: false,
                  };
                })
              }
            />
          </Panel>
        </div>
        <div id="filter-group-user-type">
          <Panel header="User Type">
            <CustomRefinementList
              attribute="userType"
              predefinedValue={[USER_TYPES.FREELANCER]}
              placeholder="Search for user type"
              showCount
              searchable
              showChip
            />
          </Panel>
        </div>
        <div id="filter-group-last-active">
          <Panel header="Last activity date">
            <NumericMenu
              attribute="lastActivityDate_dt"
              items={LAST_ACTIVITY_ITEMS}
              transformItems={(items) => {
                return items.map((item) => {
                  return {
                    ...item,
                    noRefinement: false,
                  };
                });
              }}
            />
          </Panel>
        </div>
        <div id="filter-group-sign-up-date">
          <Panel header="Sign Up Date">
            <NumericMenu
              attribute="createdAt_dt"
              items={CREATED_AT_ITEMS}
              transformItems={(items) => {
                return items.map((item) => {
                  return {
                    ...item,
                    noRefinement: false,
                  };
                });
              }}
            />
          </Panel>
        </div>
        <div id="filter-group-agreed-to-marketing">
          <ToggleRefinement
            attribute="agreedToMarketing"
            label="Agreed to marketing"
            value={false}
            defaultRefinement={false}
          />
        </div>
        <div id="filter-group-agreed-to-terms">
          <ToggleRefinement
            attribute="agreedToTerms"
            label="Agreed to terms"
            value={true}
            defaultRefinement={false}
          />
        </div>
      </DynamicWidgets>
    </>
  );
};

export default SearchFilters;
