import React, { useContext, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { debounce } from "lodash";

import { AlertContext, AuthContext } from "context/providers";

import JobListingCard from "components/JobListingCard";
import { Button } from "components/ui/button";
import { ChevronRight } from "lucide-react";
import { customSearchClient } from "utils/helpers/search";
import {
  Configure,
  Hits,
  HitsPerPage,
  InstantSearch,
  Pagination,
  Stats,
} from "react-instantsearch-dom";
import JobSearchFilters from "components/JobSearchFilters";

export default function JobOppListV2() {
  const { user: loggedInUser } = useContext(AuthContext);

  const isAdminGroup = useMemo(
    () =>
      loggedInUser.attributes.groups.includes(
        process.env.REACT_APP_COGNITO_ADMIN_GROUP
      ),
    [loggedInUser]
  );

  const [query, setQuery] = useState("");
  const [searchState, setSearchState] = useState({
    refinementList: {
      status: ["ACTIVE"],
      "torcOwner.username": [loggedInUser.username],
    },
  });
  const [showFilters, setShowFilters] = useState(true);
  const { clearAlerts } = useContext(AlertContext);

  const handleInputChangeDebounce = debounce(({ target }) => {
    setQuery(target.value);
  }, 350);

  useEffect(() => {
    clearAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InstantSearch
      searchClient={customSearchClient}
      indexName={process.env.REACT_APP_JOB_INDEX}
      searchState={searchState}
      onSearchStateChange={setSearchState}
    >
      <div className="w-full flex flex-column">
        <div
          className={classNames(
            "transition-width duration-300 ease-in-out",
            "relative border-r-2",
            {
              "w-1/4": showFilters,
              "w-0": !showFilters,
            }
          )}
        >
          <Button
            className={classNames(
              "absolute -top-10 left-0 border-2 rounded-none rounded-tr-md z-50"
            )}
            onClick={() => setShowFilters((current) => !current)}
            variant="ghost"
            size="icon"
          >
            <ChevronRight
              className={classNames("transition-transform", {
                "rotate-180": showFilters,
                "rotate-0": !showFilters,
              })}
            />
          </Button>

          <div className="sticky top-0 pt-3 z-10 overflow-hidden">
            <div className="flex flex-col gap-2 px-4">
              <div className="-mx-4 overflow-y-scroll h-screen overflow-x-hidden px-2 customScrollBar mt-2 pb-16">
                <JobSearchFilters
                  searchState={searchState}
                  showCustomerFilter={isAdminGroup}
                  onSearchInputChange={handleInputChangeDebounce}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames("transition-width duration-300 ease-in-out", {
            "w-[100%]": !showFilters,
            "w-[75%]": showFilters,
          })}
        >
          <div className="flex flex-col items-center gap-y-8 pb-8">
            <div className="flex items-baseline justify-between">
              <h3 className="text-2xl font-bold p-4">Job Search</h3>
              <p>
                <Stats />
              </p>
            </div>
            <div className="max-w-[790px] flex flex-col w-full gap-y-4">
              <Hits hitComponent={JobListingCard} className="w-full" />

              <div className="flex w-full justify-between items-center px-2">
                <Pagination />
                <HitsPerPage
                  items={[
                    { value: 10, label: "Show 10 hits" },
                    { value: 25, label: "Show 25 hits" },
                  ]}
                  defaultRefinement={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Configure query={query} />
    </InstantSearch>
  );
}
