import React, { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { ThemeProvider, Authenticator } from "@aws-amplify/ui-react";
import { HashRouter, Switch } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { ToastContainer } from "react-toastify";

import RootProvider from "context/providers";
import awsConfig from "./aws-exports";

import { SearchContainer, ModalContainer } from "containers";
import UserSearch from "pages/UserSearch";
import JobOpportunities from "pages/JobOppList";
import JobOppDetails from "pages/JobOppDetails";

import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";

import AppLogo from "components/Logo";
import JobAssistant from "components/JobAssistant";
import { PrivateRoute } from "components/PrivateRoute";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "apollo";
import PageViewTracker from "components/PageViewTracker";
import TalentCreation from "pages/TalentCreation";
import { urlMatchesLocation } from "utils/helpers/urlMatchesLocation";
import JobOppListV2 from "pages/JobOppListv2";

awsConfig.oauth.domain =
  process.env.REACT_APP_COGNITO_HOSTED_DOMAIN || awsConfig.oauth.domain;

const acceptabelSignInURLs = awsConfig.oauth.redirectSignIn.split(",");
const acceptableSignOutURLs = awsConfig.oauth.redirectSignOut.split(",");

awsConfig.oauth.redirectSignIn = acceptabelSignInURLs.find(urlMatchesLocation);
awsConfig.oauth.redirectSignOut =
  acceptableSignOutURLs.find(urlMatchesLocation);

// More details about cookieStorage config see link
// https://docs.amplify.aws/javascript/prev/build-a-backend/auth/set-up-auth/#set-up-and-connect-backend-resources
// "Set up your Auth backend resources" -> "Existing Resources"
awsConfig.Auth = {
  ...awsConfig.Auth,
  cookieStorage: {
    domain: window.location.hostname,
    sameSite: "strict",
    secure: true,
  },
};

Amplify.configure(awsConfig);

const components = {
  SignIn: {
    Header() {
      return (
        <div className="flex justify-center pt-8">
          <AppLogo />
        </div>
      );
    },
  },
};

export default function App() {
  useEffect(() => {
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PMVDVJWM');
    `;
    document.head.appendChild(gtmScript);
  }, []);

  return (
    <ThemeProvider>
      <Authenticator
        socialProviders={["google"]}
        hideSignUp
        components={components}
      >
        {({ signOut }) => (
          <>
            <RootProvider>
              {/*
                Once RootProvider gets refactored to remove unneceesary providers, ApolloProvider can encapsulate it
              */}
              <ApolloProvider client={apolloClient}>
                <HashRouter>
                  <CompatRouter>
                    <PageViewTracker />
                    <Switch>
                      <PrivateRoute
                        path="/"
                        exact
                        signOut={signOut}
                        component={JobOppListV2}
                      />
                      <PrivateRoute
                        path="/jobs-legacy"
                        exact
                        signOut={signOut}
                        component={JobOpportunities}
                      />
                      <PrivateRoute
                        path="/assistant"
                        exact
                        signOut={signOut}
                        component={JobAssistant}
                        isAssistantRoute
                      />
                      <PrivateRoute
                        signOut={signOut}
                        component={() => (
                          <SearchContainer>
                            <UserSearch />
                          </SearchContainer>
                        )}
                        path="/usermanager"
                        exact
                      />
                      <PrivateRoute
                        signOut={signOut}
                        component={() => (
                          <SearchContainer>
                            <JobOppDetails />
                          </SearchContainer>
                        )}
                        path="/jobOpps/:id/details"
                        exact
                      />
                      <PrivateRoute
                        signOut={signOut}
                        component={TalentCreation}
                        path="/talent-creation"
                      />
                    </Switch>
                  </CompatRouter>
                </HashRouter>
              </ApolloProvider>

              <ModalContainer />
            </RootProvider>

            <ToastContainer autoClose={3000} theme="dark" />
          </>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}
