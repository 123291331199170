import classNames from "classnames";
import { ExternalLink as ExternalLinkIcon } from "lucide-react";

export function JobField({ label, value, valueHref }) {
  return (
    <div className="flex flex-col gap-y-2 w-1/2 sm:w-min">
      <p className="uppercase text-sm text-gray-400 font-bold">{label}</p>
      <p
        className={classNames(
          {
            "inline-block lowercase first-letter:uppercase": label === "Status",
          },
          "text-sm"
        )}
      >
        <JobFieldValue label={label} value={value} valueHref={valueHref} />
      </p>
    </div>
  );
}

function JobFieldValue({ label, value, valueHref }) {
  if (!!valueHref) {
    return (
      <a
        className={classNames(
          {
            "inline-block lowercase first-letter:uppercase": label === "Status",
          },
          "flex text-sm items-baseline gap-1 transition-all text-sky-500 opacity-90 hover:opacity-100 hover:underline"
        )}
        target="_blank"
        rel="noreferrer"
        href={valueHref}
      >
        {value}
        <ExternalLinkIcon size={12} strokeWidth={2.25} />
      </a>
    );
  }

  return (
    <span
      className={classNames(
        {
          "inline-block lowercase first-letter:uppercase": label === "Status",
        },
        "text-sm"
      )}
    >
      {value}
    </span>
  );
}
