import React, { useContext, useEffect, useState } from "react";
import { cloneDeep, set } from "lodash";

import api from "apiSingleton";
import { getJobOrganization } from "utils/helpers/jobOppDetails";
import {
  JOB_OPPORTUNITY_TIME_COMMITMENT,
  EMPLOYMENT_TYPES,
  CURRENCY_CONVERSION_CONFIG,
} from "lookup";

import { AuthContext, JobsContext, NotesContext } from "context/providers";

import Input from "components/base/Input";
import HelpText from "components/HelpText";
import Notes from "components/Notes";
import PrefillPitch from "components/PrefillPitch";
import DemoManager from "components/DemoManager";
import Button from "components/Button";
import BullhornMetadata from "components/BullhornMetadata";
import { getPurifiedParsedMarkDown } from "utils/markdown";
import {
  baseToTargetCurrency,
  loggedUserShouldINRConvert,
} from "utils/helpers/users";

// Display Job Length, if Weeks is present, display weeks,
// else display months
const JobLength = ({ jobOpp }) => {
  if (jobOpp?.jobLengthInWeeks) {
    return (
      <span>
        {jobOpp.jobLengthInWeeks}{" "}
        {jobOpp.jobLengthInWeeks > 1 ? "weeks" : "week"}
      </span>
    );
  }

  if (jobOpp?.jobLength) {
    return (
      <span>
        {jobOpp?.jobLength} {jobOpp?.jobLength > 1 ? "months" : "month"}
      </span>
    );
  }

  return null;
};

const JobOppDetailsCard = ({ user, setEditJobLinkClicked }) => {
  const { loadingJobOpp, jobOpp, updateJob, updateJobLocally } =
    useContext(JobsContext);
  const { user: loggedInUser } = useContext(AuthContext);

  const {
    jobOppNotes,
    job: initJobNotes,
    add: addNote,
    remove: removeNote,
  } = useContext(NotesContext);

  const [inputs, setInputs] = useState({
    torcOwner: {
      value: jobOpp.torcOwner?.username || "",
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      focused: false,
    },
  });

  const getTimeCommitmentLabel = () => {
    if (jobOpp.timeCommitment === JOB_OPPORTUNITY_TIME_COMMITMENT.FULLTIME) {
      return "Full-time";
    }

    return "Part-time";
  };

  const getStartDate = () => {
    const startDate = new Date(jobOpp.startDate);
    if (startDate <= new Date()) {
      return "Immediate";
    }

    const year = startDate.getFullYear();
    const month = (1 + startDate.getMonth()).toString().padStart(2, "0");
    const day = startDate.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  const handleInputChange = ({ value, valueKey }) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: {
        ...prev[valueKey],
        errorMessage: "",
        value,
      },
    }));
  };

  const updateInputByPath = (path, value) => {
    setInputs((prev) => {
      const output = cloneDeep(prev);
      const updated = set(output, path, value);

      return updated;
    });
  };

  const addPrimaryMatcher = async () => {
    const shouldUpdate =
      !!inputs.torcOwner.value &&
      inputs.torcOwner.value !== jobOpp.torcOwner?.username;

    let userData;

    if (shouldUpdate) {
      const searchByEmail = inputs.torcOwner.value.includes("@");
      const payloadKey = searchByEmail ? "getUserByEmail" : "getUserByUsername";

      updateInputByPath("torcOwner.isLoading", true);

      try {
        let user;

        if (searchByEmail) {
          user = await api.user.getByEmail({
            email: inputs.torcOwner.value,
          });
        } else {
          user = await api.user.getByUsername({
            username: inputs.torcOwner.value,
          });
        }

        const id = user.data?.[payloadKey]?.items?.[0];

        if (id) {
          userData = user.data?.[payloadKey]?.items?.[0];
        } else {
          updateInputByPath("torcOwner.errorMessage", "User not found");
          updateInputByPath("torcOwner.isLoading", false);

          return;
        }
      } catch (error) {
        updateInputByPath("torcOwner.errorMessage", "User not found");
        updateInputByPath("torcOwner.isLoading", false);
      }

      try {
        const payload = { ...userData };

        await updateJob(jobOpp.id, { torcOwner: { ...payload } });

        updateInputByPath("torcOwner.focused", false);
        updateInputByPath(
          "torcOwner.successMessage",
          "Primary Matcher successfully updated"
        );

        updateJobLocally(payload, "torcOwner");

        setTimeout(() => {
          updateInputByPath("torcOwner.successMessage", "");
        }, 3000);
      } catch (error) {
        const errorMessage =
          error.errors?.[0]?.message || "Something went wrong";

        updateInputByPath("torcOwner.errorMessage", errorMessage);
      } finally {
        updateInputByPath("torcOwner.isLoading", false);
        updateInputByPath("torcOwner.value", userData.username);
      }
    } else {
      updateInputByPath("torcOwner.focused", false);
    }
  };

  const initNotes = async () => {
    try {
      await initJobNotes(jobOpp);
    } catch (error) {
      console.error("initJobNotes error: ", error);
    }
  };

  const createNote = async (note) => {
    try {
      const { content, isPublic } = note;
      const { id: jobOpportunityId } = jobOpp;

      await addNote({ content, isPublic, jobOpportunityId });
      await initNotes();

      return { isSuccess: true };
    } catch (error) {
      console.error("createNote error: ", error);

      return { isSuccess: false };
    }
  };

  const updateFreelancerPitchPrefill = async (field) => {
    try {
      await updateJob(jobOpp.id, { freelancerPitchPrefill: field.value });

      updateJobLocally(field.value, "freelancerPitchPrefill");

      return { isSuccess: true };
    } catch (error) {
      console.error("update error: ", error);

      return { isSuccess: false };
    }
  };

  const handleDeleteNote = async (id) => {
    await removeNote({ id });
    await initNotes();
  };

  useEffect(() => {
    if (jobOpp?.torcOwner?.username) {
      updateInputByPath("torcOwner.value", jobOpp.torcOwner.username);
    }
  }, [jobOpp?.torcOwner?.username]);

  if (loadingJobOpp) {
    return (
      <div className="flex justify-center pt-8">
        <span className="loader"></span>
      </div>
    );
  }

  const renderSkills = (skills, title) => {
    return (
      <div>
        <h3 className="text-base font-medium text-black tracking-wide font-nexa">
          {title}
        </h3>
        <h4 className="font-rubik text-base italic text-blue-400 font-light">
          {skills?.map((s) => s.name).join(", ")}
        </h4>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <div style={{ flex: `1.7 1 0px` }}>
          <div className="px-8 py-8 w-full">
            <div className="text-blue-400 font-nexa text-xl mb-4">
              Job Details
            </div>
            <ul className="text-blue-400 font-rubik mb-4 pb-2 font-bold italic leading-snug">
              <li>
                {getTimeCommitmentLabel()} | <JobLength jobOpp={jobOpp} />
              </li>

              <li>
                Customer Name: <span>{getJobOrganization(jobOpp)}</span>
              </li>
              <li>
                Start Date: <span>{getStartDate()}</span>
              </li>
              <li>
                {jobOpp.maxRate ? (
                  <>
                    Hourly Rate:{" "}
                    <span>
                      up to ${jobOpp.maxRate?.value}
                      {loggedUserShouldINRConvert(loggedInUser) &&
                        ` USD (${baseToTargetCurrency(
                          jobOpp.maxRate?.value,
                          CURRENCY_CONVERSION_CONFIG.exchangeFactor
                        )} INR)`}
                    </span>
                  </>
                ) : (
                  <>
                    Yearly Salary:{" "}
                    <span>
                      up to ${jobOpp.maxSalary?.value}
                      {loggedUserShouldINRConvert(loggedInUser) &&
                        ` USD (${baseToTargetCurrency(
                          jobOpp.maxSalary?.value,
                          CURRENCY_CONVERSION_CONFIG.exchangeFactor
                        )} INR)`}
                    </span>
                  </>
                )}
              </li>
              <li>
                Timezone: <span>{jobOpp.timezone?.label}</span>
              </li>
              <li>
                Timezone Overlap:{" "}
                <span>
                  {jobOpp.timeOverlap > 1 && jobOpp.timeOverlap < 8
                    ? `${jobOpp.timeOverlap} hours`
                    : jobOpp.timeOverlap >= 8
                    ? "All hours"
                    : "No Restriction"}
                </span>
              </li>
              <li>
                Job Role: <span>{jobOpp.jobType?.title}</span>
              </li>
              {jobOpp.employmentType && (
                <li>
                  Employment:{" "}
                  <span className="lowercase first-letter:uppercase">
                    {EMPLOYMENT_TYPES[jobOpp.employmentType]}
                  </span>
                </li>
              )}
              {jobOpp.priorityLevel && (
                <li>
                  Priority: <span>{jobOpp.priorityLevel}</span>
                </li>
              )}
              {jobOpp.workMode && (
                <li className="inline-flex gap-1">
                  Mode:
                  <span className="lowercase first-letter:uppercase">
                    {jobOpp.workMode}
                  </span>
                </li>
              )}

              {inputs.torcOwner.focused ? (
                <Input
                  className="!flex-row items-center"
                  labelClassName="!my-0 mr-2"
                  inputClassName="border-dashed !p-1 not-italic focus:border-solid text-black font-normal"
                  label="Primary Matcher: "
                  placeholder="Rep username or email"
                  helpText="Internal User working on this job"
                  helpTextPlacement="left"
                  errorMessage={inputs.torcOwner.errorMessage}
                  isLoading={inputs.torcOwner.isLoading}
                  value={inputs.torcOwner.value}
                  valueKey="torcOwner"
                  onChange={handleInputChange}
                  onBlur={addPrimaryMatcher}
                  autoFocus
                />
              ) : (
                <div className="relative flex flex-col">
                  <div className="select-none flex">
                    <span>Primary Matcher:</span>

                    <HelpText
                      text="Internal User working on this job"
                      className="mx-2"
                    />
                    <span
                      title="Double click to edit primary matcher"
                      className="cursor-pointer"
                      onDoubleClick={() =>
                        updateInputByPath("torcOwner.focused", true)
                      }
                    >
                      {inputs.torcOwner.value || "Not specified"}
                    </span>
                  </div>
                  <p className="text-green-500 text-sm font-bold mt-1 transition-all">
                    {inputs.torcOwner.successMessage}
                  </p>
                </div>
              )}
            </ul>
            <Notes
              notes={jobOppNotes[jobOpp.id]}
              notesTarget="Job"
              loggedInUser={user}
              notesCount={jobOpp.notesCount}
              onSubmit={createNote}
              onDelete={handleDeleteNote}
              borderTop
            />
            <PrefillPitch
              editorLabel="Job Questions:"
              className="mt-8"
              field={jobOpp.freelancerPitchPrefill || ""}
              onSubmit={updateFreelancerPitchPrefill}
              borderTop
            />
            {process.env.REACT_APP_ENV === "demo" && (
              <DemoManager jobOppId={jobOpp.id} key={jobOpp.id} />
            )}
            <BullhornMetadata
              metadata={jobOpp.metadata}
              borderTop
              showTitle
              className="mt-8 text-blue-400"
            />
          </div>
        </div>
        <div
          className="bg-zestygreen"
          style={{ flexBasis: `2px`, alignSelf: `stretch` }}
        />
        <div className="p-12 flex flex-col gap-8" style={{ flex: `2 1 0px` }}>
          <div className="flex gap-8 self-end">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_DOMAIN}/#/jobs/opportunities/${jobOpp.id}/edit`}
              onClick={() => {
                setEditJobLinkClicked(true);
              }}
            >
              <Button>
                <span className="w-full text-center">Edit Job</span>
              </Button>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_SFDC_BASE_URL}/customFlowListOperation?platformjobid=${jobOpp.id}`}
            >
              <Button type="submit">
                <span className="w-full text-center">SFDC Job Flow</span>
              </Button>
            </a>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="text-3xl font-bold text-black tracking-wide font-nexa">
              {jobOpp.title}
            </h1>
            {renderSkills(jobOpp.skills)}
            {jobOpp.optionalSkills?.length > 0 &&
              renderSkills(jobOpp.optionalSkills, "Optional Skills")}
          </div>
          <p
            className="prose"
            dangerouslySetInnerHTML={{
              __html: getPurifiedParsedMarkDown(jobOpp.overview || ""),
            }}
          />
          <h3 className="-mb-6 text-lg">Responsibilities</h3>
          <p
            className="prose"
            dangerouslySetInnerHTML={{
              __html: getPurifiedParsedMarkDown(jobOpp.responsibilities || ""),
            }}
          />
          <h3 className="-mb-6 text-lg">Requirements</h3>
          <p
            className="prose"
            dangerouslySetInnerHTML={{
              __html: getPurifiedParsedMarkDown(jobOpp.requirements || ""),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JobOppDetailsCard;
