import React, { createContext, useReducer, useContext } from "react";

import { contextConstants } from "context/constants";
import { contextActions } from "context/actions";
import { contextReducers } from "context/reducers";
import { AlertContext } from "./AlertProvider";
import {
  GEOGRAPHICAL_REGIONS_CONFIG,
  JOB_OPPORTUNITY_STATUSES,
  YEARS_OF_EXPERIENCE_CONFIG,
  YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED,
} from "lookup";
import { convertSalaryToRatePerHour } from "utils/helpers/users";

export const JobsContext = createContext();

const allFilters = [...Object.values(JOB_OPPORTUNITY_STATUSES)];

const isCountryAllowedForYearsOfExperience = (countryName) => {
  if (!YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED) {
    return false;
  }

  return YEARS_OF_EXPERIENCE_CONFIG.locationCountryNames.includes(countryName);
};

const checkJobIsAllowedForYearsOfExp = (jobOpp) => {
  if (jobOpp.location?.countryName) {
    // Country should be in the whitelist
    return isCountryAllowedForYearsOfExperience(jobOpp.location.countryName);
  } else if (jobOpp.geographicalRegions?.length > 0) {
    // ALL countries should be in the whitelist
    const countries = jobOpp.geographicalRegions
      .map((grOuter) => {
        if (grOuter.countryNames.length === 0) {
          return [
            ...(GEOGRAPHICAL_REGIONS_CONFIG?.find(
              (grInner) => grInner.regionName === grOuter.regionName
            )?.countryNames || []),
          ];
        }

        return [...grOuter.countryNames];
      })
      .flat();

    return countries.every(isCountryAllowedForYearsOfExperience);
  }

  return false;
};

export const JobsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.jobs.reducer,
    contextReducers.jobs.initialState
  );

  const alertsContext = useContext(AlertContext);

  const initializedJobsChange = (initializeJobs) => {
    const {
      jobOpps,
      jobTypes,
      companyNames,
      jobSkills,
      torcOwners,
      jobOptionalSkills,
    } = initializeJobs;

    dispatch({
      type: contextConstants.jobs.JOB_OPPS_LOADED,
      payload: { jobOpps },
    });

    dispatch({
      type: contextConstants.jobs.JOB_TYPES_LOADED,
      payload: {
        jobTypes: Object.keys(jobTypes).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.COMPANY_NAMES_LOADED,
      payload: {
        companyNames: Object.keys(companyNames).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.JOB_SKILLS_LOADED,
      payload: {
        jobSkills: Object.keys(jobSkills).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.JOB_OPTIONAL_SKILLS_LOADED,
      payload: {
        jobOptionalSkills: Object.keys(jobOptionalSkills).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.TORC_OWNERS_LOADED,
      payload: {
        torcOwners: Object.values(torcOwners).sort((a, b) => {
          if (a.username < b.username) {
            return -1;
          }
          if (a.username > b.username) {
            return 1;
          }
          return 0;
        }),
      },
    });
  };

  const init = async () => {
    dispatch({
      type: contextConstants.jobs.JOB_OPPS_LOADING,
      payload: { isLoading: true },
    });

    const initializeJobs = await contextActions.jobs.initializeJobs(
      state.statusFilter.includes("All") ? allFilters : state.statusFilter
    );

    initializedJobsChange(initializeJobs);
  };

  const initJob = async (jobId) => {
    let jobOpp = null;
    try {
      jobOpp = await contextActions.jobs.initializeJob(jobId, alertsContext);

      // NOTE: By setting requiredExperience to null the whole application will handle this
      // years of experience filters as disabled
      if (!checkJobIsAllowedForYearsOfExp(jobOpp)) {
        jobOpp.requiredExperience = null;
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: contextConstants.jobs.JOB_OPP_LOADED,
        payload: { jobOpp },
      });
    }
  };

  const initJobCalendarEvents = async (jobId) => {
    try {
      const jobCalendarEvents =
        await contextActions.jobs.initializeJobCalendarEvents(jobId);

      dispatch({
        type: contextConstants.jobs.JOB_OPP_CALENDAR_EVENTS_LOADED,
        payload: { jobCalendarEvents },
      });
    } catch (error) {
      alertsContext.addGraphQLAlert(error);
      dispatch({
        type: contextConstants.jobs.JOB_OPP_CALENDAR_EVENTS_LOADED,
        payload: { jobCalendarEvents: [] },
      });
    }
  };

  const updateJob = async (jobId, payload) => {
    await contextActions.jobs.updateJob(jobId, payload);
  };

  const updateJobLocally = async (value, valueKey) => {
    const jobOpp = await contextActions.jobs.updateJobLocally(
      state.jobOpp,
      value,
      valueKey
    );

    if (!checkJobIsAllowedForYearsOfExp(jobOpp)) {
      jobOpp.requiredExperience = null;
    }

    dispatch({
      type: contextConstants.jobs.JOB_OPP_LOADED,
      payload: { jobOpp },
    });
  };

  const clearJob = () => {
    dispatch({
      type: contextConstants.jobs.JOB_OPP_CLEARED,
    });
  };

  const updateCompanyFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.COMPANY_FILTER_UPDATED,
      payload: { companyFilter: newValue },
    });
  };

  const updateJobTypeFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_TYPE_FILTER_UPDATED,
      payload: { jobTypeFilter: newValue },
    });
  };

  const updateJobSkillsFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_SKILLS_FILTER_UPDATED,
      payload: { jobSkillsFilter: newValue },
    });
  };

  const updateJobOptionalSkillsFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_OPTIONAL_SKILLS_FILTER_UPDATED,
      payload: { jobOptionalSkillsFilter: newValue },
    });
  };

  const updateStatusFilter = async (newValue) => {
    dispatch({
      type: contextConstants.jobs.STATUS_FILTER_UPDATED,
      payload: { statusFilter: newValue },
    });

    const initializeJobs = await contextActions.jobs.initializeJobs(
      newValue.includes("All") ? allFilters : newValue
    );

    initializedJobsChange(initializeJobs);
  };

  const clearFilters = () => {
    dispatch({ type: contextConstants.jobs.JOB_FILTERS_CLEARED });
  };

  const determineHourlyRate = () => {
    if (state.jobOpp.minRate) {
      return [
        Math.floor(state.jobOpp.minRate.value),
        Math.ceil(state.jobOpp.maxRate.value),
      ];
    } else {
      return [
        convertSalaryToRatePerHour(state.jobOpp.minSalary.value),
        convertSalaryToRatePerHour(state.jobOpp.maxSalary.value),
      ];
    }
  };

  const resetSimilarJobOppsFilters = () => {
    dispatch({
      type: contextConstants.jobs.SIMILAR_JOB_OPPS_FILTERS_LOADED,
      payload: {
        skills: state.jobOpp.skills?.map((sk) => sk.name) || [],
        skillsOperator: "or",
        optionalSkills: [],
        optionalSkillsOperator: "or",
        limit: 1000,
        vectorSearchScore: 83,
        hourlyRate: determineHourlyRate(),
      },
    });
  };

  const updateSimilarJobsFilters = (key, value) => {
    dispatch({
      type: contextConstants.jobs.SIMILAR_JOB_OPP_FILTER_CHANGE,
      payload: { key, value },
    });
  };

  const updateTorcOwnerFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.TORC_OWNERS_FILTER_UPDATED,
      payload: { torcOwnersFilter: newValue },
    });
  };

  const updateTitleSearchFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.TITLE_SEARCH_FILTER_UPDATED,
      payload: { titleSearchFilter: newValue },
    });
  };

  const updatedCustomerOwnerFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.CUSTOMER_OWNER_FILTER_UPDATED,
      payload: { customerOwnerFilter: newValue },
    });
  };

  const updateGeographicalRegionFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.GEOGRAPHICAL_REGIONS_FILTER_UPDATED,
      payload: { geographicalRegionFilter: newValue },
    });
  };

  const updateJobIdFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_ID_UPDATED,
      payload: { jobIdFilter: newValue },
    });
  };

  const jobOpps = {
    jobOpp: state.jobOpp,
    jobCalendarEvents: state.jobCalendarEvents,
    jobs: state.jobOpps,
    similarJobsFilters: state.similarJobsFilters,
    companyFilter: state.companyFilter,
    companyNames: state.companyNames,
    jobTypeFilter: state.jobTypeFilter,
    jobTypes: state.jobTypes,
    jobSkillsFilter: state.jobSkillsFilter,
    jobOptionalSkillsFilter: state.jobOptionalSkillsFilter,
    statusFilter: state.statusFilter,
    jobSkills: state.jobSkills,
    jobOptionalSkills: state.jobOptionalSkills,
    loadingJobOpp: state.loadingJobOpp,
    isLoading: state.isLoading,
    torcOwnersFilter: state.torcOwnersFilter,
    torcOwners: state.torcOwners,
    titleSearchFilter: state.titleSearchFilter,
    customerOwnerFilter: state.customerOwnerFilter,
    geographicalRegionFilter: state.geographicalRegionFilter,
    jobIdFilter: state.jobIdFilter,
    updateCompanyFilter,
    updateJobTypeFilter,
    updateJobSkillsFilter,
    updateJobOptionalSkillsFilter,
    updateStatusFilter,
    initJob,
    initJobCalendarEvents,
    updateJob,
    updateJobLocally,
    clearJob,
    clearFilters,
    resetSimilarJobOppsFilters,
    updateSimilarJobsFilters,
    init,
    updateTorcOwnerFilter,
    updateTitleSearchFilter,
    updatedCustomerOwnerFilter,
    updateGeographicalRegionFilter,
    updateJobIdFilter,
  };

  return (
    <JobsContext.Provider value={jobOpps}>{children}</JobsContext.Provider>
  );
};
