import { CustomPanel } from "components/SearchFilters/components";
import SkillsRefinementList from "components/SearchFilters/components/SkillsRefinementList";
import { JobsContext, SearchContext } from "context/providers";
import { SKILL_STR_FORMATTED_OPTIONS } from "lookup";
import { useContext } from "react";

const SkillsFilterWrapper = ({ operatorChange, attribute }) => {
  const { searchState, operators, handleSkillsStrChange, disjunctiveFacets } =
    useContext(SearchContext);

  const { jobOpp } = useContext(JobsContext);

  const handleSkillsExpChange = (newItem, checked) => {
    let newSkillsStr;

    if (checked) {
      newSkillsStr = [...skillsStr, newItem];
    } else {
      newSkillsStr = skillsStr.filter((item) => !item.startsWith(newItem));
    }

    handleSkillsStrChange(newSkillsStr);
  };

  const handleSkillsNameChange = (skillsName) => {
    if (skillsStr) {
      const newSubOptions = skillsStr.filter((item) =>
        skillsName.some((sk) => item.startsWith(sk))
      );
      handleSkillsStrChange(newSubOptions, skillsName);
    }
  };

  const getSkillsStrCount = (skillsStrItem) => {
    const skillsStrFacets = disjunctiveFacets.find(
      ({ name }) => name === "skills_str"
    );

    return skillsStrFacets?.data?.[skillsStrItem];
  };

  const skillsStr = searchState.skillsStr;

  return (
    <>
      <CustomPanel
        id="filter-group-skills"
        title="Skills"
        checked={operators[attribute] === "and"}
        valueKey={attribute}
        leftToggleLabel="OR"
        rightToggleLabel="AND"
        helpText="If you would like to filter multiple skills. Ex:  C# and Java. Select OR if you want to see talent with either of the skill. Select AND if you would like to see the only talents with both the skills."
        onChange={(value, valueKey) =>
          operatorChange(value, valueKey, "operators")
        }
        toggle
      >
        <SkillsRefinementList
          placeholder="Search for skills"
          subRefinementSkillsExpOptions={SKILL_STR_FORMATTED_OPTIONS}
          subRefinementSkillsExp={skillsStr}
          skills={jobOpp.skills || []}
          optionalSkills={jobOpp.optionalSkills || []}
          attribute={attribute}
          operator={operators[attribute]}
          limit={15}
          showMoreLimit={1000}
          currentRefinementChange={handleSkillsNameChange}
          subRefinementSkillsExpChange={handleSkillsExpChange}
          getSubRefinementSkillsExpCount={getSkillsStrCount}
          showCount
          searchable
          showMore
          showChip
        />
      </CustomPanel>
    </>
  );
};

export default SkillsFilterWrapper;
