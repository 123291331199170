import { gql } from "@apollo/client";

export const GET_JOB_OPPORTUNITY_MATCH_STATS = gql`
  query GetJobMatchStats($jobId: ID!) {
    getJobOpportunity(id: $jobId) {
      matches(limit: 1000) {
        items {
          status
          subStatus
        }
      }
    }
  }
`;
