import {
  ClearRefinements,
  Panel,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom";

import CustomCheckboxRefinement from "./CustomToggleRefinement";

export default function JobSearchFilters({
  searchState,
  showCustomerFilter,
  onSearchInputChange,
}) {
  const matcherLimit =
    searchState?.refinementList?.["torcOwner.username"]?.length || 1;

  return (
    <>
      <Panel header="Text Search">
        <SearchBox
          translations={{
            placeholder: "Find by job id or title",
          }}
          showLoadingIndicator
          autoFocus
          searchAsYouType={false}
          onChange={onSearchInputChange}
          onReset={() => {
            onSearchInputChange({ target: { value: "" } });
          }}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        />
      </Panel>

      <ClearRefinements
        className="w-[150px] mb-2 mt-4 justify-self-end"
        transformItems={(items) => items.filter(({ attribute }) => attribute)}
      />

      {/* Default Refinements set through search state prop on React Instantsearch */}
      <Panel header="Matcher">
        {/* Default Refinements set through search state prop on React Instantsearch */}
        {!searchState?.toggle?.["hasMatcher"] && (
          <RefinementList
            translations={{
              placeholder: "Find more matchers",
            }}
            className="mb-4"
            attribute="torcOwner.username"
            limit={matcherLimit}
            showMore
            searchable
          />
        )}

        {!searchState?.refinementList?.["torcOwner.username"]?.length && (
          <CustomCheckboxRefinement
            className="hidden"
            attribute="hasMatcher"
            label="No Matcher"
            value={false}
          />
        )}
      </Panel>

      <Panel header="Status">
        <RefinementList attribute="status" limit={2} showMore operator="or" />
      </Panel>

      {showCustomerFilter && (
        <Panel header="Customer">
          <RefinementList
            translations={{
              placeholder: "Find more customers",
            }}
            attribute="organization"
            limit={10}
            showMore
            searchable
          />
        </Panel>
      )}

      <Panel header="Job Role">
        <RefinementList attribute="jobTypeTitle" limit={20} showMore />
      </Panel>

      <Panel header="Region">
        <RefinementList attribute="geographicalRegions.regionName" limit={20} />
      </Panel>

      <Panel header="Required Skills">
        <RefinementList
          translations={{
            placeholder: "Find more skills",
          }}
          attribute="skills.name"
          limit={10}
          searchable
          showMore
        />
      </Panel>

      <Panel header="Optional Skills">
        <RefinementList
          translations={{
            placeholder: "Find more skills",
          }}
          attribute="optionalSkills.name"
          limit={10}
          searchable
          showMore
        />
      </Panel>
    </>
  );
}
