import {
  ArrowRightToLine,
  BadgeCheck,
  CircleX,
  HeartIcon,
  MoreVertical,
  NotebookPen,
  SlidersVertical,
  XIcon,
} from "lucide-react";
import { useContext, useState, useMemo } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import {
  APPLICATION_TAB_NAMES,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
  JOB_OPPORTUNITY_STATUSES,
  USER_LIST_VIEWS,
} from "lookup";
import { JobsContext, MatchContext, ModalContext } from "context/providers";
import SvgIcon from "components/base/SvgIcon";
import classNames from "classnames";
import { getButtonText } from "components/UserCardList/helpers/userCard";
import RejectionForm from "../UserCardResult/molecules/RejectionForm";
import Modal from "components/Modals";
import CustomerRateButton from "../UserCardResult/molecules/MatchActionButtons/CustomerRateButton";
import CustomerRateModal from "../UserCardResult/molecules/MatchActionButtons/CustomerRateModal";

function MatchActionDropDown({ row, activeTabName, collectionKey }) {
  const { jobOpp } = useContext(JobsContext);
  const { updateMatch: contextUpdateMatch, removeMatch: contextRemoveMatch } =
    useContext(MatchContext);
  const { showModal } = useContext(ModalContext);

  const { match } = row.original;
  const { applicationId } = match;
  const { id: jobOpportunityId } = jobOpp;
  const hit = row.original;

  const [isLoading, setIsLoading] = useState({
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
    [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.NULL]: false,
    [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]: false,
    [JOB_APPLICATION_MATCH_STATUS.APPLIED]: false,
    isCalibration: false,
  });

  const removeMatch = async () => {
    const confirmRemove = window.confirm(
      `Are you sure you want to delete ${row.original.username} match?`
    );

    if (confirmRemove) {
      setIsLoading((prev) => ({
        ...prev,
        [JOB_APPLICATION_MATCH_STATUS.DELETED]: true,
      }));

      try {
        await contextRemoveMatch(applicationId, jobOpportunityId);
      } catch (error) {
        console.log("removeExistingMatch error: ", error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
        }));
      }
    }
  };

  const updateMatch = async (args) => {
    let attrs = {};

    if (hit.match) {
      if (hit.match.localCustomerSummary) {
        attrs.customerSummary = hit.match.localCustomerSummary;
      }

      if (hit.match.localAnalysis) {
        attrs.analysis = { ...hit.match.localAnalysis };
      }
    }

    if (args?.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) {
      // passing the value to the modal so it can be viewed in the modal
      if (hit.match) {
        if (hit.match.customerSummary || hit.match.localCustomerSummary) {
          attrs.customerSummary =
            hit.match.customerSummary || hit.match.localCustomerSummary;
        }

        if (hit.match.analysis?.report || hit.match.localAnalysis) {
          attrs.analysis = {
            score:
              hit.match?.analysis?.score ?? hit.match?.localAnalysis?.score,
            report:
              hit.match?.analysis?.report || hit.match?.localAnalysis?.report,
          };
        }
      }

      showModal({
        type: "match",
        applicationId,
        jobOpportunityId,
        collectionKey,
        status: args?.status,
        hitStatus: hit.match?.status,
        userId: hit.match?.userId || hit.id,
        isUpdate: true,
        args: { ...attrs },
      });
      return;
    }

    if (args?.subStatus === JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST) {
      showModal({
        type: "matchAnalysis",
        applicationId,
        jobOpportunityId,
        collectionKey,
        subStatus: args?.subStatus,
        isUpdate: true,
      });
      return;
    }

    if (args.isCalibration) {
      showModal({
        type: "calibration",
        applicationId,
        jobOpportunityId,
        collectionKey,
        isCalibration: args.isCalibration,
        isUpdate: true,
        args: { ...attrs },
      });
      return;
    }
    setIsLoading((prev) => ({
      ...prev,
      [args.status ||
      (args.subStatus !== undefined ? args.subStatus : "isCalibration")]: true,
    }));

    try {
      await contextUpdateMatch(applicationId, jobOpportunityId, args);
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        [args.status ||
        (args.subStatus !== undefined
          ? args.subStatus
          : "isCalibration")]: false,
      }));
    }
  };

  const isJobStatusActive = jobOpp.status === JOB_OPPORTUNITY_STATUSES.ACTIVE;
  const hasPrimaryMatcher = jobOpp.torcOwner?.username;

  const [showRejectModal, setShowRejectModal] = useState(false);

  const canBeFinalist =
    match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED ||
    match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED;

  const isNotFinalist =
    match?.subStatus !== JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST;

  const isNotCalibration = !match?.isCalibration;

  const noCalibrationStatuses = [
    JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
    JOB_APPLICATION_MATCH_STATUS.SKIPPED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
  ];

  const canBeCalibration = !noCalibrationStatuses.includes(match.status);

  const { oneOfButtonsLoading, notesCount, canBeMatched } = useMemo(() => {
    const oneOfButtonsLoading = !!Object.values(isLoading).filter((el) => !!el)
      .length;

    const { status, notesCount } = match;
    const canBeMatched =
      status === JOB_APPLICATION_MATCH_STATUS.SHORTLISTED ||
      status === JOB_APPLICATION_MATCH_STATUS.PRESHORTLISTED ||
      status === JOB_APPLICATION_MATCH_STATUS.INTERESTED;

    return { oneOfButtonsLoading, notesCount, canBeMatched };
  }, [isLoading, match]);

  const showRejectShortlisted =
    activeTabName === APPLICATION_TAB_NAMES.SHORTLISTED &&
    (match.status === JOB_APPLICATION_MATCH_STATUS.INTERESTED ||
      match.status === JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK);

  const showRejectMatched = activeTabName === APPLICATION_TAB_NAMES.MATCHED;

  const handleRejectClick = () => {
    if (showRejectShortlisted) {
      updateMatch({
        status: JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
      });
    } else {
      setShowRejectModal(true);
    }
  };

  const [settingRate, setSettingRate] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open action menu</span>
            <MoreVertical className="h-5 w-5 text-gray-500" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {canBeCalibration && isNotCalibration && (
            <DropdownMenuItem
              onClick={() =>
                updateMatch({
                  isCalibration: isNotCalibration ? true : false,
                })
              }
              disabled={oneOfButtonsLoading}
              className={
                isNotCalibration ? "" : "hover:!fill-red-500 hover:text-red-500"
              }
            >
              <div className="h-5 w-5">
                <SlidersVertical size={20} />
              </div>
              {getButtonText(
                isNotCalibration ? "calibration" : "removeCalibration"
              )}
            </DropdownMenuItem>
          )}

          {canBeFinalist && (
            <DropdownMenuItem
              onClick={() =>
                updateMatch({
                  subStatus: isNotFinalist
                    ? JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST
                    : JOB_APPLICATION_MATCH_SUB_STATUS.NULL,
                })
              }
              disabled={oneOfButtonsLoading}
              className={classNames(
                isNotFinalist ? "hover:!fill-red-500 hover:text-red-500" : ""
              )}
            >
              <div className="grid place-items-center h-5 w-5">
                <BadgeCheck size={20} />
              </div>
              {getButtonText(isNotFinalist ? "finalist" : "removeFinalist")}
            </DropdownMenuItem>
          )}

          {activeTabName === APPLICATION_TAB_NAMES.CALIBRATION && (
            <CustomerRateButton
              match={match}
              disabled={oneOfButtonsLoading}
              isCalibration={!isNotCalibration}
              setSettingRate={setSettingRate}
              viewType={USER_LIST_VIEWS.LIST}
              activeTabName={activeTabName}
            />
          )}

          {!canBeMatched &&
            activeTabName !== APPLICATION_TAB_NAMES.CALIBRATION && (
              <CustomerRateButton
                match={match}
                disabled={oneOfButtonsLoading}
                setSettingRate={setSettingRate}
                viewType={USER_LIST_VIEWS.LIST}
                activeTabName={activeTabName}
              />
            )}

          {canBeMatched && (
            <DropdownMenuItem
              disabled={
                oneOfButtonsLoading || !isJobStatusActive || !hasPrimaryMatcher
              }
              onClick={() => {
                if (!match.user?.hasActivePlacements) {
                  updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.MATCHED });
                } else if (
                  window.confirm(
                    "Are you sure you want to match this user, even though they have active engagement(s) currently?"
                  )
                ) {
                  updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.MATCHED });
                }
              }}
              type="match"
            >
              <div className="grid place-items-center h-5 w-5">
                <SvgIcon
                  type={match.user?.hasActivePlacements ? "warning" : "heart"}
                  className={"!w-full"}
                />
              </div>
              {getButtonText("match")}
            </DropdownMenuItem>
          )}

          {match.status ===
            JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK && (
            <DropdownMenuItem
              disabled={
                oneOfButtonsLoading || !isJobStatusActive || !hasPrimaryMatcher
              }
              onClick={() =>
                updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.APPLIED })
              }
              iconType="heart"
            >
              <div className="grid place-items-center h-5 w-5">
                <HeartIcon size={20} />
              </div>
              {getButtonText("apply")}
            </DropdownMenuItem>
          )}

          {match.status !== JOB_APPLICATION_MATCH_STATUS.INTERESTED &&
            match.status !==
              JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK && (
              <DropdownMenuItem
                disabled={oneOfButtonsLoading}
                onClick={removeMatch}
              >
                <div className="grid place-items-center h-5 w-5">
                  <CircleX size={20} />
                </div>

                {getButtonText(
                  "delete",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.DELETED]
                )}
              </DropdownMenuItem>
            )}

          {activeTabName === APPLICATION_TAB_NAMES.SHORTLISTED &&
            match.status === JOB_APPLICATION_MATCH_STATUS.SHORTLISTED && (
              <DropdownMenuItem
                disabled={oneOfButtonsLoading}
                onClick={() =>
                  updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.SKIPPED })
                }
              >
                <div className="grid place-items-center h-5 w-5">
                  <ArrowRightToLine size={20} />
                </div>

                {getButtonText(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )}
              </DropdownMenuItem>
            )}

          {(showRejectShortlisted || showRejectMatched) && (
            <DropdownMenuItem
              disabled={oneOfButtonsLoading}
              onClick={handleRejectClick}
            >
              <div className="grid place-items-center h-5 w-5">
                <XIcon size={20} />
              </div>

              {getButtonText(
                "reject",
                isLoading[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]
              )}
            </DropdownMenuItem>
          )}

          <DropdownMenuItem
            onClick={() =>
              showModal({
                type: "note",
                title: `Match Notes: ${row.original.username}`,
                applicationId: match.applicationId,
                isAllowedPublicNote: true,
                showGenerateAnalysisBasedOnNote: true,
              })
            }
          >
            <div className="grid place-items-center h-5 w-5">
              <NotebookPen size={20} />
            </div>
            Notes{!!notesCount ? ` (${notesCount})` : ""}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        title="Rejection Details"
        isVisible={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        className="!overflow-hidden !max-h-[65vh]"
      >
        <RejectionForm
          applicationId={applicationId}
          jobOpportunityId={jobOpportunityId}
          isVisible={showRejectModal}
          onClose={() => setShowRejectModal(false)}
        />
      </Modal>
      <CustomerRateModal
        isCalibration={activeTabName === APPLICATION_TAB_NAMES.CALIBRATION}
        match={match}
        disabled={oneOfButtonsLoading}
        setSettingRate={setSettingRate}
        settingRate={settingRate}
      />
    </>
  );
}

export default MatchActionDropDown;
