import { JOB_OPPORTUNITY_STATUSES } from "lookup";

export const getJobOrganization = (jobOpp = {}) => {
  return jobOpp?.organization || "";
};

export const formatJobStatus = (status) => {
  let txt = status;

  switch (status) {
    case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
      txt = "PENDING";
      break;
    default:
      txt = status;
      break;
  }

  return txt;
};
