import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import classNames from "classnames";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import qs from "query-string";

import {
  SearchBox,
  HitsPerPage,
  Stats,
  Pagination,
} from "react-instantsearch-dom";

import { APPLICATION_TAB_NAMES, USER_LIST_VIEWS } from "lookup";
import {
  AuthContext,
  JobsContext,
  MatchContext,
  SearchContext,
} from "context/providers";
import { mapHits } from "utils/helpers/search";
import { mapMatchesToHit } from "utils/helpers/match";
import { createFilter, tabSupportsTableView } from "./helpers/userCard";

import UserCardResult from "./components/UserCardResult";
import DownloadResults from "components/DownloadResults";
import UserTableListView from "./components/UserTableListView";
import { Filters } from "./components";
import Checkbox from "components/base/Checkbox";
import CustomToggle from "components/base/Toggle";
import { removeMatch } from "context/actions/match";
import { ListIcon, UserIcon, RotateCcwIcon } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "components/ui/select";
import { SelectValue } from "@radix-ui/react-select";

const DummyHits = ({ message, renderMessage }) => {
  const messageClasses = classNames("text-center text-red-400 text-lg mb-3");

  if (!!renderMessage) {
    return <div className={messageClasses}>{renderMessage}</div>;
  }

  return <div className={messageClasses}>{message}</div>;
};

const SORTBYKEY = {
  MATCHDATE: "Matched Date",
  LASTNAME: "Last name",
  RATE: "Rate",
};

const SORTBYDIRECTION = {
  ASCENDING: "Ascending",
  DESCENDING: "Descending",
};

const SORTBYKEYFIELD = {
  [SORTBYKEY.MATCHDATE]: "match.updatedAt",
  [SORTBYKEY.LASTNAME]: "family_name",
  [SORTBYKEY.RATE]: "ratePerHour.value",
};

function accessNestedProperty(obj, path) {
  let keys = path.split(".");
  let current = obj;

  for (let key of keys) {
    if (!current[key]) {
      return;
    }
    current = current[key];
  }

  return current;
}

function sortComparator(a, b, field) {
  const valueA = accessNestedProperty(a, SORTBYKEYFIELD[field]);
  const valueB = accessNestedProperty(b, SORTBYKEYFIELD[field]);

  if (valueA && valueB) {
    if (field === SORTBYKEY.MATCHDATE) {
      return new Date(valueA) - new Date(valueB);
    }

    if (field === SORTBYKEY.LASTNAME) {
      return valueA.localeCompare(valueB);
    }

    if (field === SORTBYKEY.RATE) {
      return valueA - valueB;
    }
  } else if (!valueA) {
    return 1;
  } else if (!valueB) {
    return -1;
  }

  return Number.NEGATIVE_INFINITY;
}

const UserCardList = ({
  activeTabName,
  showStatusColor,
  stats,
  search,
  pagination,
  downloadResults,
  collectionKey,
  noResultsMessage,
  renderCustomNoResults,
  showActions,
  excludedMatchStatuses,
  filterOutHitByMatchStatus,
  matchStatus,
  filter,
  customFilter,
  allowSorting,
}) => {
  const {
    matches,
    bestFit,
    ideal,
    loadingIdeal,
    mappedHitsMatchesBestFit,
    updateMappedHitsMatchesBestFit,
  } = useContext(MatchContext);
  const {
    hits,
    hideSkipped,
    setHideSkipped,
    hideRejected,
    setHideRejected,
    hideMatchedAndApplied,
    setHideMatchedAndApplied,
    searchState,
  } = useContext(SearchContext);
  const { user: loggedInUser } = useContext(AuthContext);

  const { jobOpp, updateJob, updateJobLocally } = useContext(JobsContext);

  const [filters, setFilters] = useState({});
  const [sortOrder, setSortOrder] = useState({
    key: SORTBYKEY.MATCHDATE,
    direction: SORTBYDIRECTION.DESCENDING,
  });
  const [jobIsUpdating, setJobIsUpdating] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentViewType = params.get("viewType");

  const activeTabSupportsTableView =
    loggedInUser.canAccessListView && tabSupportsTableView(activeTabName);

  const [viewType, setViewType] = useState(
    (activeTabSupportsTableView && currentViewType) ||
      USER_LIST_VIEWS.FULL_PROFILE
  );

  const handleViewTypeChange = (vt) => {
    const queryString = qs.stringify({
      ...qs.parse(location.search),
      viewType: vt,
    });
    navigate(`${location.pathname}?${queryString}`, { replace: true });
    setViewType(vt);
  };

  const { finalItems, sourceItems } = useMemo(() => {
    let finalItems = [];
    let sourceItems;

    switch (collectionKey) {
      case "hits": {
        finalItems = mapHits(hits, matches);
        sourceItems = mapHits(hits, matches);
        break;
      }

      case "bestFit": {
        finalItems = mapHits(bestFit, matches, filterOutHitByMatchStatus);
        sourceItems = mapHits(bestFit, matches, filterOutHitByMatchStatus);
        break;
      }

      case "ideal": {
        finalItems = mapHits(ideal, matches, filterOutHitByMatchStatus);
        sourceItems = mapHits(ideal, matches, filterOutHitByMatchStatus);
        break;
      }

      case "match": {
        finalItems = mapMatchesToHit(
          matches,
          matchStatus,
          activeTabName === APPLICATION_TAB_NAMES.CALIBRATION,
          excludedMatchStatuses
        );
        sourceItems = mapMatchesToHit(
          matches,
          matchStatus,
          activeTabName === APPLICATION_TAB_NAMES.CALIBRATION,
          excludedMatchStatuses
        );
        break;
      }

      default: {
        break;
      }
    }

    if (!!filter.length) {
      const finalItemsCpy = finalItems.filter((hit) => {
        let conditionValue;

        Object.keys(filters).forEach((filtersKey) => {
          const filtersByKey = filters[filtersKey].keys || {};
          Object.keys(filtersByKey).forEach((filterKey) => {
            if (filtersByKey[filterKey].value) {
              if (filters[filtersKey].filterCondition) {
                conditionValue =
                  conditionValue && hit.match?.[filtersKey] === filterKey;
              } else {
                conditionValue =
                  conditionValue || hit.match?.[filtersKey] === filterKey;
              }
            }
          });
        });

        return conditionValue;
      });

      if (sortOrder.key) {
        finalItemsCpy.sort((a, b) => {
          if (sortOrder.direction === SORTBYDIRECTION.ASCENDING) {
            return sortComparator(a, b, sortOrder.key);
          } else {
            return sortComparator(b, a, sortOrder.key);
          }
        });
      }

      return { finalItems: finalItemsCpy, sourceItems };
    }

    if (sortOrder.key) {
      finalItems.sort((a, b) => {
        if (sortOrder.direction === SORTBYDIRECTION.ASCENDING) {
          return sortComparator(a, b, sortOrder.key);
        } else {
          return sortComparator(b, a, sortOrder.key);
        }
      });
    }

    return { finalItems, sourceItems };
  }, [
    activeTabName,
    collectionKey,
    hits,
    bestFit,
    matches,
    matchStatus,
    excludedMatchStatuses,
    filterOutHitByMatchStatus,
    filter,
    filters,
    sortOrder,
    ideal,
  ]);

  const renderCustomFilter = () => {
    return (
      <div className="w-full flex flex-wrap gap-x-4">
        {customFilter.includes("hideSkipped") && (
          <Checkbox
            label="Hide Skipped Users"
            className="mt-0"
            checked={hideSkipped}
            onChange={setHideSkipped}
          />
        )}
        {customFilter.includes("hideRejected") && (
          <Checkbox
            label="Hide Rejected Users"
            className="mt-0"
            checked={hideRejected}
            onChange={setHideRejected}
          />
        )}
        {customFilter.includes("hideMatchedAndApplied") && (
          <Checkbox
            label="Hide Matched/Applied Users"
            className="mt-0"
            checked={hideMatchedAndApplied}
            onChange={setHideMatchedAndApplied}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    // Store selected applicants to be used in export csv
    if (collectionKey !== "hits") {
      updateMappedHitsMatchesBestFit(finalItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalItems.length, updateMappedHitsMatchesBestFit, collectionKey]);

  useEffect(() => {
    // Clean mappedHitsMatchesBestFit for Top Candidates tab
    if (collectionKey === "hits" && mappedHitsMatchesBestFit) {
      updateMappedHitsMatchesBestFit(null);
    }
  }, [collectionKey, mappedHitsMatchesBestFit, updateMappedHitsMatchesBestFit]);

  useEffect(() => {
    if (!!filter.length) {
      const createdFilters = {};
      filter.forEach((f) => {
        createdFilters[f] = { keys: createFilter(sourceItems, f) };
        createdFilters[f].filterCondition = false;
      });

      // When setting or re-setting the filters, we need to take
      // any new filters while still keeping the state of the old filters
      // as long as the old filters are still a part of the new filters
      // Here, data structure of filter is:
      // {
      //   [string]: {
      //     filterCondition: boolean,
      //     keys: {
      //       label: string,
      //       value: boolean
      //     }[]
      //   }
      // }
      setFilters((prev) => {
        const finalFilters = {};

        Object.keys(createdFilters).forEach((outerKey) => {
          finalFilters[outerKey] = JSON.parse(
            JSON.stringify(createdFilters[outerKey])
          );

          if (finalFilters[outerKey].hasOwnProperty("keys")) {
            finalFilters[outerKey].keys = {};

            Object.keys(createdFilters[outerKey].keys).forEach((innerKey) => {
              if (
                prev[outerKey] &&
                prev[outerKey].keys &&
                prev[outerKey].keys.hasOwnProperty(innerKey)
              ) {
                finalFilters[outerKey].keys[innerKey] =
                  prev[outerKey].keys[innerKey];
              } else {
                finalFilters[outerKey].keys[innerKey] =
                  createdFilters[outerKey].keys[innerKey];
              }
            });
          }
        });

        return finalFilters;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!filter.length, sourceItems.length]);

  const handleCalibrationChange = async (value, key) => {
    setJobIsUpdating(true);
    await updateJob(jobOpp.id, { [key]: value });
    updateJobLocally(value, key);
    setJobIsUpdating(false);
  };

  const handleSortOrderChange = (value) => {
    const [sortOrderKey, sortOrderDirection] = value.split("-");

    if (!sortOrderDirection) {
      setSortOrder({ key: null, direction: null });
    } else {
      setSortOrder({ key: sortOrderKey, direction: sortOrderDirection });
    }
  };

  const showResetButton = () => {
    if (activeTabName !== APPLICATION_TAB_NAMES.SHORTLISTED) return false;

    const show = finalItems.some(
      (item) => item.match?.status === "SHORTLISTED"
    );
    return show;
  };

  const handleResetButton = async () => {
    if (!finalItems) return;

    const confirmDeleting = window.confirm(
      "Are you sure you want to delete all shortlisted candidates?"
    );

    if (!confirmDeleting) return;

    const promises = finalItems.map(async (item) => {
      if (item.match?.status === "SHORTLISTED") {
        return await removeMatch(
          item.match?.applicationId,
          item.match?.jobOpportunityId
        );
      }
    });

    await Promise.all(promises);

    return;
  };

  const resultsFound = !!finalItems.length;
  const noResultBasicSearch =
    searchState.basicSearch && !searchState.customSearchQuery;

  return (
    <div
      className={classNames("flex flex-col gap-4 h-full px-6 pt-2", {
        "bg-slate-100": resultsFound,
      })}
    >
      <div className="flex justify-between items-center">
        {customFilter?.length > 0 &&
          !searchState.basicSearch &&
          renderCustomFilter()}

        {activeTabName === APPLICATION_TAB_NAMES.CALIBRATION && (
          <div
            className={classNames("text-sm font-semibold text-indigo-800", {
              "animate-pulse": jobIsUpdating,
            })}
            style={{ color: "#5a5e9a" }}
          >
            <CustomToggle
              checked={jobOpp?.calibrationIsEnabled ? true : false}
              valueKey={"calibrationIsEnabled"}
              leftLabel="OFF"
              rightLabel="ON"
              onChange={handleCalibrationChange}
            />
          </div>
        )}

        {stats && !noResultBasicSearch && <Stats />}
      </div>

      {search && (
        <SearchBox
          translations={{
            placeholder: "Search for users",
          }}
          autoFocus
        />
      )}

      <div className={"flex items-center gap-y-4 gap-x-8 flex-wrap"}>
        {activeTabSupportsTableView &&
          resultsFound &&
          !searchState.basicSearch && (
            <div className="flex h-10 items-center space-x-1 rounded-md border bg-background p-1">
              <button
                className={classNames(
                  {
                    "bg-accent text-accent-foreground":
                      viewType === USER_LIST_VIEWS.LIST,
                  },
                  "w-32 justify-center flex gap-2 cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none focus:bg-accent focus:text-accent-foreground"
                )}
                onClick={() => handleViewTypeChange(USER_LIST_VIEWS.LIST)}
              >
                <ListIcon size={16} />
                {USER_LIST_VIEWS.LIST}
              </button>
              <button
                className={classNames(
                  {
                    "bg-accent text-accent-foreground":
                      viewType === USER_LIST_VIEWS.FULL_PROFILE,
                  },
                  "w-32 justify-center flex gap-2 cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none focus:bg-accent focus:text-accent-foreground"
                )}
                onClick={() =>
                  handleViewTypeChange(USER_LIST_VIEWS.FULL_PROFILE)
                }
              >
                <UserIcon size={16} />
                {USER_LIST_VIEWS.FULL_PROFILE}
              </button>
            </div>
          )}

        <div className="flex justify-end gap-4 items-center ml-auto">
          {!!filter.length && (
            <Filters filters={filters} onChange={setFilters} />
          )}

          <div className="flex items-center gap-3">
            {showResetButton() && (
              <div title="Reset Shortlist">
                <RotateCcwIcon
                  label="Reset"
                  onClick={handleResetButton}
                  className=" cursor-pointer"
                />
              </div>
            )}

            <div className="flex-1 flex gap-2 items-center justify-end flex-wrap">
              {allowSorting && (
                <Select
                  value={`${sortOrder.key}-${sortOrder.direction}`}
                  onValueChange={(value) => handleSortOrderChange(value)}
                >
                  <SelectTrigger value="">
                    <SelectValue placeholder="Sort By" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Object.entries(SORTBYKEY).map(([key, value]) =>
                        Object.entries(SORTBYDIRECTION).map(
                          ([dirKey, dirValue]) => (
                            <SelectItem
                              value={`${value}-${dirValue}`}
                              key={`${value}-${dirValue}`}
                            >
                              {`${value} (${dirValue})`}
                            </SelectItem>
                          )
                        )
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTabName === APPLICATION_TAB_NAMES.IDEAL && loadingIdeal ? (
        <DummyHits
          message={loadingIdeal}
          renderMessage={renderCustomNoResults}
        />
      ) : (
        <UserListView
          items={finalItems}
          activeTabName={activeTabName}
          showStatusColor={showStatusColor}
          showActions={showActions}
          collectionKey={collectionKey}
          viewType={viewType}
          message={noResultsMessage}
          renderMessage={renderCustomNoResults}
        />
      )}

      {pagination && !searchState.basicSearch && (
        <div className="my-8 flex justify-between">
          <Pagination />

          <HitsPerPage
            items={[
              { value: 10, label: "Show 10 hits" },
              { value: 25, label: "Show 25 hits" },
            ]}
            defaultRefinement={10}
          />
        </div>
      )}

      {downloadResults && <DownloadResults />}
    </div>
  );
};

const UserListView = ({
  items,
  activeTabName,
  showStatusColor,
  showActions,
  collectionKey,
  viewType,
  message,
  renderMessage,
}) => {
  const { jobOpp } = useContext(JobsContext);
  const { searchState } = useContext(SearchContext);

  const { user: loggedInUser } = useContext(AuthContext);

  const resultsFound = !!items.length;

  if (searchState.basicSearch && !searchState.customSearchQuery) {
    return <></>;
  }

  if (!resultsFound || !jobOpp) {
    // we don't need to render the full message if basic search is enabled
    const dummyRenderMessage = searchState.basicSearch ? null : renderMessage;
    return <DummyHits message={message} renderMessage={dummyRenderMessage} />;
  }

  const activeTabSupportsTableView =
    loggedInUser.canAccessListView && tabSupportsTableView(activeTabName);
  const allowTableView = activeTabSupportsTableView && !searchState.basicSearch;

  if (!allowTableView || viewType === USER_LIST_VIEWS.FULL_PROFILE) {
    return (
      <UserCardListView
        items={items}
        activeTabName={activeTabName}
        showStatusColor={showStatusColor}
        showActions={showActions}
        collectionKey={collectionKey}
      />
    );
  }

  return (
    <UserTableListView
      items={items}
      activeTabName={activeTabName}
      showStatusColor={showStatusColor}
      showActions={showActions}
      collectionKey={collectionKey}
    />
  );
};

const UserCardListView = ({
  items,
  activeTabName,
  showStatusColor,
  showActions,
  collectionKey,
}) => {
  return items.map((hit) => {
    const clonedHit = cloneDeep(hit);

    return (
      <UserCardResult
        key={clonedHit.id}
        hit={clonedHit}
        activeTabName={activeTabName}
        showStatusColor={showStatusColor}
        showActions={showActions}
        collectionKey={collectionKey}
      />
    );
  });
};

UserCardList.propTypes = {
  showStatusColor: PropTypes.bool,
  stats: PropTypes.bool,
  search: PropTypes.bool,
  pagination: PropTypes.bool,
  downloadResults: PropTypes.bool,
  collectionKey: PropTypes.string,
  noResultsMessage: PropTypes.string,
  activeTabName: PropTypes.string,
  renderCustomNoResults: PropTypes.object,
  showActions: PropTypes.bool,
  filter: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([""])]),
  customFilter: PropTypes.array,
  excludedMatchStatuses: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
  matchStatus: PropTypes.array,
  allowSorting: PropTypes.bool,
};

UserCardList.defaultProps = {
  showStatusColor: false,
  stats: false,
  search: false,
  pagination: false,
  downloadResults: false,
  collectionKey: "hits",
  noResultsMessage: "No results to display",
  activeTabName: "TOPCANDIDATES",
  renderCustomNoResults: undefined,
  showActions: false,
  filter: [],
  customFilter: [],
  excludedMatchStatuses: undefined,
  matchStatus: undefined,
  allowSorting: false,
};

export default UserCardList;
