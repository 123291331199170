import React from "react";
import classNames from "classnames";

import { JOB_APPLICATION_MATCH_STATUS, USER_LIST_VIEWS } from "lookup";

import SvgIcon from "components/base/SvgIcon";
import { DropdownMenuItem } from "components/ui/dropdown-menu";
import { tabSupportsTableView } from "components/UserCardList/helpers/userCard";

const EXCLUDED_RATE_BUTTON_STATUSES = [
  JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
  JOB_APPLICATION_MATCH_STATUS.SKIPPED,
  JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
];

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const CustomerRateButton = ({
  match,
  disabled,
  isCalibration,
  setSettingRate,
  viewType,
  activeTabName,
}) => {
  const activeTabSupportsTableView = tabSupportsTableView(activeTabName);

  if (EXCLUDED_RATE_BUTTON_STATUSES.includes(match?.status)) return null;

  if (viewType === USER_LIST_VIEWS.LIST && activeTabSupportsTableView) {
    return (
      <DropdownMenuItem
        onClick={() => setSettingRate(true)}
        disabled={disabled}
      >
        <div className="grid place-items-center h-5 w-5">
          <SvgIcon type="focus" className="w-[20px] h-[20px]" />
        </div>
        {isCalibration ? "Calibration" : "Customer"} rate
      </DropdownMenuItem>
    );
  }

  return (
    <button
      className={classNames(
        DEFAULT_BUTTON_CLASS_NAME,
        "text-gray-600 hover:!fill-sky-500 hover:text-sky-500"
      )}
      onClick={() => setSettingRate(true)}
      disabled={disabled}
    >
      <SvgIcon type="focus" className="w-[20px] h-[20px]" />

      <span className="ml-2">
        {isCalibration ? "Calibration" : "Customer"} rate
      </span>
    </button>
  );
};

export default CustomerRateButton;
