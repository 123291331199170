import React, { useContext, useMemo, useState, useEffect } from "react";
import classNames from "classnames";

import { JobsContext, ModalContext, SearchContext } from "context/providers";

import ActionButtonsPanel from "components/ActionButtonsPanel";
import SearchFilters from "components/SearchFilters";
import ApplicationsTabs from "./components/ApplicationsTabs";
import SavedSearches from "components/SavedSearches";
import UserCardList from "components/UserCardList";

import { useLocation } from "react-router-dom-v5-compat";
import IdealFitFilter from "components/SearchFilters/idealFitFilters";
import { TABS } from "context/constants/common";
import { ChevronRight, LoaderCircle, X } from "lucide-react";

import "./index.css";
import CustomToggle from "components/base/Toggle";
import { Button } from "components/ui/button";
import { debounce, isEqual } from "lodash";

const CustomSearchBox = ({
  onChange,
  onClear,
  value,
  className,
  showLoadingIndicator,
  placeholder = "Search...",
  id,
}) => {
  const { isSearchStalled } = useContext(SearchContext);

  return (
    <div id={id} className={classNames("ais-SearchBox w-full", className)}>
      <div className="ais-SearchBox-form">
        <input
          type="search"
          placeholder={placeholder}
          className="ais-SearchBox-input"
          onChange={onChange}
          value={value}
        />
        {showLoadingIndicator && isSearchStalled && (
          <span className="ais-SearchBox-loadingIndicator !right-0.5">
            <LoaderCircle
              strokeWidth={1}
              size={20}
              className="animate-spin opacity-50"
            />
          </span>
        )}
        {((showLoadingIndicator && !isSearchStalled) ||
          !showLoadingIndicator) &&
          value && (
            <button
              type="reset"
              title="Clear the search query"
              className="ais-SearchBox-reset !right-0.5 w-[18px]"
              onClick={onClear}
            >
              <X color="#484c7a" />
            </button>
          )}
      </div>
    </div>
  );
};

const durationLength = 300;
const timeoutBuffer = 50;
const widthTransitionClasses = classNames(
  "transition-width ease-in-out",
  `duration-${durationLength}`
);

const handleRequiredInputChangeDebounce = debounce(
  (value, setSearchState, searchState) => {
    setSearchState({
      ...searchState,
      customSearchQuery: value,
    });
  },
  350
);

const handleOptionalInputChangeDebounce = debounce(
  (value, setSearchState, searchState) => {
    setSearchState({
      ...searchState,
      configProps: {
        ...searchState.configProps,
        optionalWords: value,
      },
    });
  },
  350
);

// https://stackoverflow.com/a/65050864/2104976
// Each widget is responsibile for its own state
// We cannot set the default value for job title
// if the refinement list does not exist
// But we don't want to show the refinement list for job title
// So we are creating this "virtual" refinement list

const Applications = ({ user }) => {
  const { showModal } = useContext(ModalContext);
  const { jobOpp, jobCalendarEvents } = useContext(JobsContext);
  const {
    searchState,
    setSearchState,
    clearSearchState,
    AISkillSearchConfig,
    handleSkillSearchEnabledChange,
    setHideSkipped,
    setHideRejected,
    setHideMatchedAndApplied,
  } = useContext(SearchContext);

  const isJobPage = useMemo(() => !!jobOpp.id, [jobOpp]);
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentTab = params.get("tab");
  const tabSupportsFilters =
    !currentTab ||
    currentTab === TABS.TOPCANDIDATES ||
    currentTab === TABS.IDEALFIT;
  const [showLeftSidebar, setShowLeftSidebar] = useState(tabSupportsFilters);
  const [showFilters, setShowFilters] = useState(tabSupportsFilters);

  const [requiredInputSearch, setRequiredInputSearch] = useState("");

  const [optionalInputSearch, setOptionalInputSearch] = useState("");

  useEffect(() => {
    setShowLeftSidebar(() => tabSupportsFilters);

    const timeoutAmount = tabSupportsFilters
      ? 0
      : durationLength + timeoutBuffer;
    const timeoutId = setTimeout(() => {
      setShowFilters(() => {
        if (tabSupportsFilters) {
          return true;
        }
        return false;
      });
    }, timeoutAmount);

    return () => clearTimeout(timeoutId);
  }, [tabSupportsFilters]);

  const clearOptionalWords = () => {
    const finalState = {
      ...searchState,
      configProps: {
        ...searchState.configProps,
        optionalWords: "",
      },
    };
    setSearchState(finalState);
  };

  useEffect(() => {
    if (!isEqual(searchState.customSearchQuery, requiredInputSearch)) {
      setRequiredInputSearch(searchState.customSearchQuery || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.customSearchQuery]);

  useEffect(() => {
    if (!isEqual(searchState.configProps?.optionalWords, optionalInputSearch)) {
      setOptionalInputSearch(searchState.configProps?.optionalWords || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.configProps?.optionalWords]);

  const handleRequiredInputChange = ({ target }) => {
    setRequiredInputSearch(target.value);

    handleRequiredInputChangeDebounce(
      target.value,
      setSearchState,
      searchState
    );
  };

  const handleOptionalInputChange = ({ target }) => {
    setOptionalInputSearch(target.value);

    handleOptionalInputChangeDebounce(
      target.value,
      setSearchState,
      searchState
    );
  };

  return (
    <div
      className={classNames("w-full flex flex-column", {
        "!border-t-2": isJobPage,
      })}
    >
      <div
        className={classNames(
          widthTransitionClasses,
          "relative border-r-2 min-h-[calc(100vh-64px)]",
          {
            "w-1/4": showLeftSidebar,
            "w-0": !showLeftSidebar,
            hidden: !showFilters,
            block: showFilters,
          }
        )}
      >
        {tabSupportsFilters && (
          <Button
            className={classNames(
              "absolute -top-10 left-0 border-2 rounded-none rounded-tr-md z-50"
            )}
            onClick={() => setShowLeftSidebar((current) => !current)}
            variant="ghost"
            size="icon"
          >
            <ChevronRight
              className={classNames("transition-transform", {
                "rotate-180": showLeftSidebar,
                "rotate-0": !showLeftSidebar,
              })}
            />
          </Button>
        )}

        <div className="sticky top-0 pt-3 z-10 overflow-hidden">
          <div
            className={classNames({
              hidden: currentTab !== TABS.IDEALFIT,
            })}
          >
            <IdealFitFilter />
          </div>
          <div
            className={classNames({
              hidden: currentTab === TABS.IDEALFIT,
            })}
          >
            <div className="flex flex-col gap-2 px-4">
              <CustomToggle
                leftLabel="ADVANCED"
                rightLabel="BASIC"
                checked={searchState.basicSearch}
                className="text-sm font-bold text-gray-500"
                onChange={(basicSearchOn) => {
                  // Storing as string since getting error cannot delete Cannot delete property 'x' of #<Object>
                  // when destructuring as an object.
                  const previousAdvanceSearchStateStr =
                    JSON.stringify(searchState);

                  let finalState = {};

                  if (basicSearchOn) {
                    finalState = {
                      ...searchState,
                      previousAdvanceSearchStateStr,
                      customSearchQuery: "",
                      numericFilters: [],
                    };

                    setHideSkipped(false);
                    setHideRejected(false);
                    setHideMatchedAndApplied(false);
                  } else {
                    finalState = {
                      ...searchState,
                      customSearchQuery: "",
                      ...JSON.parse(searchState.previousAdvanceSearchStateStr),
                      previousAdvanceSearchStateStr: "{}",
                    };

                    setHideSkipped(true);
                    setHideRejected(true);
                    setHideMatchedAndApplied(true);
                  }
                  setSearchState({
                    ...finalState,
                    basicSearch: basicSearchOn,
                  });
                }}
              />

              <div className="flex items-center gap-4">
                {!searchState.basicSearch && (
                  <span
                    className="text-sm text-sky-500 py-0 w-min whitespace-nowrap cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
                    onClick={clearSearchState}
                  >
                    Clear Filters
                  </span>
                )}
                {!searchState.basicSearch &&
                  AISkillSearchConfig.enabled &&
                  !AISkillSearchConfig.loading && (
                    <span
                      className="text-sky-500 text-sm capitalize whitespace-nowrap cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
                      onClick={() => {
                        handleSkillSearchEnabledChange(true, true);
                      }}
                    >
                      AI Skills Reset
                    </span>
                  )}
              </div>

              <div className="flex flex-col gap-1">
                {AISkillSearchConfig.enabled && !searchState.basicSearch && (
                  <label className="text-sm text-gray-600">Required</label>
                )}
                {searchState.basicSearch ? (
                  <CustomSearchBox
                    id="basic-search"
                    showLoadingIndicator={AISkillSearchConfig.enabled}
                    onChange={handleRequiredInputChange}
                    onClear={() => {
                      setSearchState({
                        ...searchState,
                        customSearchQuery: "",
                      });
                    }}
                    value={requiredInputSearch}
                    placeholder={"Name, email, username"}
                  />
                ) : (
                  <CustomSearchBox
                    id="required-skills"
                    showLoadingIndicator={AISkillSearchConfig.enabled}
                    onChange={handleRequiredInputChange}
                    onClear={() => {
                      setSearchState({
                        ...searchState,
                        customSearchQuery: "",
                      });
                    }}
                    value={requiredInputSearch}
                    placeholder={"Search..."}
                  />
                )}
              </div>

              {AISkillSearchConfig.enabled && !searchState.basicSearch && (
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-600">Optional</label>
                  <CustomSearchBox
                    id="optional-skills"
                    value={optionalInputSearch}
                    onChange={handleOptionalInputChange}
                    onClear={clearOptionalWords}
                  />
                </div>
              )}

              {!searchState.basicSearch && (
                <>
                  <div className="-mx-4 px-4 pt-4 border-b-2">
                    <SavedSearches />
                  </div>

                  <div className="-mx-4 overflow-y-scroll h-[72vh] lg:h-[75vh] overflow-x-hidden px-2 customScrollBar mt-2">
                    <SearchFilters />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(widthTransitionClasses, {
          "w-[95%]": !showLeftSidebar,
          "w-[70%]": showLeftSidebar,
        })}
      >
        {isJobPage ? (
          <ApplicationsTabs />
        ) : (
          <UserCardList collectionKey="hits" pagination stats />
        )}
      </div>
      <div className="w-full max-w-[5%] border-l">
        <ActionButtonsPanel
          isJobPage={isJobPage}
          user={user}
          jobCalendarEvents={jobCalendarEvents}
          showModal={showModal}
          jobOpp={jobOpp}
        />
      </div>
    </div>
  );
};

export default Applications;
