import React, { useContext } from "react";
import classNames from "classnames";
import { debounce } from "lodash";
import { RefreshCcw } from "lucide-react";

import { SearchContext } from "context/providers";

import CustomToggle from "components/base/Toggle";
import Popover from "components/ui/Popover";

function AIAssistedSkillsSearch() {
  const { AISkillSearchConfig, handleSkillSearchEnabledChange } =
    useContext(SearchContext);

  const handleSkillSearchEnabledChangedDebounce = debounce((value) => {
    if (AISkillSearchConfig.loading) {
      return;
    }

    handleSkillSearchEnabledChange(value);
  }, 500);

  const formatAISkillSearchSummary = (summary) =>
    summary
      ?.replace(/<b>(.*?)<\/b>/g, '<b class="text-indigo-800">$1</b>')
      ?.replace(/<i>(.*?)<\/i>/g, '<i class="text-sky-500">$1</i>');

  return (
    <div id="ai-skills" className="flex flex-col gap-3 items-start flex-1">
      <p className="uppercase text-base text-gray-500 font-bold">
        AI Skills 🤖
      </p>
      <div
        className={classNames("relative text-sm font-bold text-sky-500", {
          "animate-pulse": AISkillSearchConfig.loading,
        })}
      >
        <CustomToggle
          leftLabel="OFF"
          rightLabel="ON"
          checked={AISkillSearchConfig.enabled}
          onChange={handleSkillSearchEnabledChangedDebounce}
        />
        {AISkillSearchConfig.enabled && !AISkillSearchConfig.data && (
          <div className="absolute -top-5 -right-6">
            <RefreshCcw
              className={classNames(
                "duration-700 text-black transform rotate-180",
                {
                  "animate-spin": AISkillSearchConfig.loading,
                  "animate-none hover:scale-110 hover:duration-150":
                    !AISkillSearchConfig.loading,
                }
              )}
            />
          </div>
        )}
        {AISkillSearchConfig.enabled && AISkillSearchConfig.data && (
          <div className="absolute -top-1 -right-8">
            <Popover
              className={classNames(
                "md:!min-w-[500px] max-w-min md:max-w-[600px] pt-0 outline-none",
                { "!min-h-[200px]": !AISkillSearchConfig.data.summary }
              )}
            >
              <div className="flex flex-col gap-[7px]">
                <div className="flex justify-between bg-sky-500 p-4">
                  <p className="font-medium text-white">
                    🙎‍♀️ AI Skills Search by Olivia{" "}
                    <span className="text-xs">{"(analysis)"}</span>
                  </p>

                  <button
                    title="Refresh"
                    disabled={AISkillSearchConfig.loading}
                    className={classNames("outline-none", {
                      "opacity-50 border-none": AISkillSearchConfig.loading,
                    })}
                    onClick={async () => {
                      await handleSkillSearchEnabledChange(true, false, true);
                    }}
                  >
                    <RefreshCcw
                      className={classNames(
                        "duration-700 text-white transform rotate-180",
                        {
                          "animate-spin": AISkillSearchConfig.loading,
                          "animate-none hover:scale-110 hover:duration-150":
                            !AISkillSearchConfig.loading,
                        }
                      )}
                    />
                  </button>
                </div>
                <div
                  className={classNames("px-4 py-2 min-h-[25px]", {
                    "animate-pulse": AISkillSearchConfig.loading,
                  })}
                >
                  {AISkillSearchConfig.loading &&
                  !AISkillSearchConfig.data.summary ? (
                    <div className="w-full h-[125px] bg-gray-200" />
                  ) : (
                    <div
                      className="prose"
                      dangerouslySetInnerHTML={{
                        __html: formatAISkillSearchSummary(
                          AISkillSearchConfig.data.summary
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
}

export default AIAssistedSkillsSearch;
