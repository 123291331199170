import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { JobsContext, SearchContext } from "context/providers";
import Applications from "components/Applications";

const UserSearch = ({ signOut }) => {
  const { jobOpp, clearJob } = useContext(JobsContext);

  const { clearSearchState } = useContext(SearchContext);

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const searchQuery = queryParams.get("search");

  useEffect(() => {
    if (jobOpp?.id) {
      clearJob();
    }

    return () => {
      clearSearchState({ isUnMounting: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!jobOpp?.id) {
      // InstantSearch may still setting state from the unmount of job page
      setTimeout(() => {
        //search query logic exists here, because this useEffect always resets any state logic
        //We should be able to move this logic to searchContext if we can remove this setTimeout in the future
        if (searchQuery) {
          //Deleting query params to not persist state in the URL
          queryParams.delete("search");
          const newUrl =
            location.pathname +
            (queryParams.size > 0 ? `?${queryParams.toString()}` : "");
          history.replace(newUrl);
        }
        clearSearchState({
          isUnMounting: true,
          urlParamData: searchQuery
            ? {
                customSearchQuery: searchQuery,
                basicSearch: true,
              }
            : null,
        });
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOpp?.id]);

  return <Applications signOut={signOut} />;
};

export default UserSearch;
