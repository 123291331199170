import React, { useContext, useMemo } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import {
  JOB_APPLICATION_MATCH_STATUS,
  SEARCH_BY_ATTRIBUTES,
  USER_STATUS,
} from "lookup";
import { MatchContext, SearchContext } from "context/providers";

import ResultsContainer from "./Results";
import { customSearchClient } from "utils/helpers/search";
import { convertMilesToMeters } from "utils/common";

const ALGOLIA_FILTERS = `status:${USER_STATUS.ACTIVE}`;

// Parse optional skills string into array format for Algolia
// Examples:
// Input: "term1 term2" -> Output: ["term1", "term2"]
// Input: 'term1 "phrase one"' -> Output: ["term1", "phrase one"]
function _parseOptionalSkillsForAlgolia(optionalSkillsString) {
  if (!optionalSkillsString) {
    return [];
  }

  // Split the string by spaces but respect quoted phrases
  const result = [];
  let currentTerm = "";
  let inQuote = false;

  for (let i = 0; i < optionalSkillsString.length; i++) {
    const char = optionalSkillsString[i];

    if (char === '"') {
      inQuote = !inQuote;
      continue; // Skip the quote character
    }

    if (char === " " && !inQuote) {
      if (currentTerm) {
        result.push(currentTerm);
        currentTerm = "";
      }
    } else {
      currentTerm += char;
    }
  }

  // Add the last term if there is one
  if (currentTerm) {
    result.push(currentTerm);
  }

  return result;
}

const SearchContainer = ({ children }) => {
  const {
    searchState,
    setSearchState,
    hideSkipped,
    hideRejected,
    hideMatchedAndApplied,
  } = useContext(SearchContext);
  const { matches } = useContext(MatchContext);

  const filteredObjectIDs = useMemo(() => {
    const skippedMatchesUserIDs = matches
      .filter((match) => match.status === JOB_APPLICATION_MATCH_STATUS.SKIPPED)
      .map((match) => match.userId);

    const rejectedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER
      )
      .map((match) => match.userId);

    const matchedAppliedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED ||
          match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED
      )
      .map((match) => match.userId);

    const filt = [];
    if (hideSkipped) filt.push(...skippedMatchesUserIDs);
    if (hideRejected) filt.push(...rejectedMatchesUserIDs);
    if (hideMatchedAndApplied) filt.push(...matchedAppliedMatchesUserIDs);

    return filt;
  }, [matches, hideSkipped, hideRejected, hideMatchedAndApplied]);

  const geoLocation = {};

  if (
    !searchState.basicSearch &&
    searchState.locationFilter?.aroundLatLng &&
    searchState.locationFilter?.aroundRadius &&
    searchState.locationFilter?.cityName
  ) {
    geoLocation.aroundLatLng = searchState.locationFilter?.aroundLatLng;

    geoLocation.aroundRadius = convertMilesToMeters(
      searchState.locationFilter.aroundRadius
    );
  }

  // when we are searching skills by text search with AI skills feature
  // we want to include the optional words in the query behind the scenes
  // so function will return a query string including the optional words
  // we added customSearchQuery and custom input
  const getSearchStateQuery = (searchState) => {
    let textSearch;
    if (!searchState.basicSearch) {
      textSearch = [
        searchState?.configProps?.optionalWords || "",
        searchState?.customSearchQuery || "",
      ];
    } else {
      textSearch = [
        searchState?.customSearchQuery
          ? `"${searchState?.customSearchQuery}"`
          : "",
      ];
    }
    return textSearch.filter((e) => e && e.length > 0).join(" ");
  };

  const getFilterStr = () => {
    let filterStr = ALGOLIA_FILTERS;
    if (!searchState.basicSearch) {
      if (!searchState.locationFilter?.aroundRadius) {
        filterStr = `${filterStr}${searchState.locationFilterStr}`;
      }
      filterStr = `${filterStr}${searchState.skillsStrFilter}${searchState.placementFilterStr}${searchState.yearsOfExperienceFilterStr}${searchState.BHStatusFilterStr}`;
    }

    return filterStr;
  };

  return (
    <InstantSearch
      searchClient={customSearchClient}
      indexName={process.env.REACT_APP_INDEX_NAME}
      onSearchStateChange={setSearchState}
      searchState={searchState}
    >
      <ResultsContainer>{children}</ResultsContainer>

      <Configure
        filters={getFilterStr()}
        facets={["objectID"]}
        facetsExcludes={{
          objectID: filteredObjectIDs,
        }}
        restrictSearchableAttributes={
          searchState.basicSearch
            ? ["email", "username", "fullName"]
            : SEARCH_BY_ATTRIBUTES[0].attributes
        }
        attributesToHighlight={
          searchState.basicSearch ? [] : SEARCH_BY_ATTRIBUTES[0].attributes
        }
        {...geoLocation}
        {...(searchState.basicSearch ? {} : searchState.configProps)}
        optionalWords={
          searchState.basicSearch
            ? []
            : _parseOptionalSkillsForAlgolia(
                searchState?.configProps?.optionalWords || ""
              )
        }
        query={getSearchStateQuery(searchState)}
      />
    </InstantSearch>
  );
};

export default SearchContainer;
