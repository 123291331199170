import React, { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { round } from "lodash";

import api from "apiSingleton";
import { AuthContext, JobsContext } from "context/providers";
import { CURRENCY_CONVERSION_CONFIG } from "lookup";

import Modal from "components/Modals";
import {
  baseToTargetCurrency,
  loggedUserShouldINRConvert,
} from "utils/helpers/users";

const getStandardMarkup = (desiredRate) => round(desiredRate * 1.5, 0);

// Display our Standard Markup
const StandardMarkup = ({ desiredRate }) => {
  if (!desiredRate) {
    return null;
  }

  return (
    <span className="font-bold">
      {"$" + getStandardMarkup(desiredRate)}{" "}
      <span
        className="font-light"
        title="1.5 times the desired rate of freelancer"
      >
        ( 1.5x )
      </span>
    </span>
  );
};

//Compute Calculated Markup based on current inputRate
const CalculatedMarkup = ({ desiredRate, currentRate }) => {
  if (!desiredRate) {
    return null;
  }

  const markup = ((currentRate - desiredRate) / currentRate) * 100;
  let markupPercent = round(markup, 2);

  if (!isFinite(markupPercent)) {
    markupPercent = 0;
  }

  return (
    <span className="font-bold">
      <span className={markupPercent > 0 ? "text-green-500" : "text-red-500"}>
        {markupPercent.toFixed(2)}
        {"%"}
      </span>
    </span>
  );
};

const CustomerRateModal = ({
  match,
  isCalibration,
  settingRate,
  setSettingRate,
}) => {
  const { initJob } = useContext(JobsContext);
  const { user: loggedInUser } = useContext(AuthContext);

  const desiredRate = match?.rate?.value;

  const initialInputNum = isCalibration
    ? match?.calibrationRate?.value || 0
    : getStandardMarkup(desiredRate ?? match?.customerRate?.value ?? 0);

  const params = useParams();

  const [inputNum, setInputNum] = useState(
    loggedUserShouldINRConvert(loggedInUser)
      ? baseToTargetCurrency(
          initialInputNum || 0,
          CURRENCY_CONVERSION_CONFIG.exchangeFactor,
          false
        )
      : initialInputNum
  );
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const usdValueRef = useRef(initialInputNum);

  const setRate = async (addRate) => {
    try {
      setLoading(true);

      const attrs = {};

      const rate = {
        value: addRate ? usdValueRef.current : 0,
        currency: "USD",
      };

      if (isCalibration) {
        attrs.calibrationRate = rate;
      } else {
        attrs.customerRate = rate;
        attrs.status = match?.status;
      }

      await api.match.update({
        input: {
          applicationId: match?.applicationId,
          jobOpportunityId: match?.jobOpportunityId,
          ...attrs,
        },
      });

      await initJob(params.id);

      setSettingRate(false);
      setLoading(false);
    } catch (e) {
      console.error("setRate error: ", e);

      setLoading(false);
      setErr(true);
    }
  };

  return (
    <Modal
      isVisible={settingRate}
      title={
        isCalibration
          ? match?.calibrationRate?.value
            ? "Edit Calibration Rate"
            : "Calibration Rate"
          : match?.customerRate?.value
          ? "Edit Customer Rate"
          : "Customer Rate"
      }
      className="!w-auto !overflow-y-hidden"
      onClose={() => {
        setSettingRate(false);
        setErr(false);
      }}
    >
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col">
          <div className="relative">
            {loggedUserShouldINRConvert(loggedInUser) && (
              <p className="absolute -top-4 -left-0 text-sm">INR</p>
            )}
            <input
              id="rate"
              type="number"
              className="border border-grey rounded-md w-32 px-2 max-w-full"
              value={inputNum}
              min="0"
              onChange={(e) => {
                if (loggedUserShouldINRConvert(loggedInUser)) {
                  const newConvertedValue =
                    CURRENCY_CONVERSION_CONFIG.exchangeFactor *
                    (e.target.value || 0);

                  usdValueRef.current = parseInt(
                    Math.ceil(Number(newConvertedValue.toFixed(2)))
                  );
                } else {
                  usdValueRef.current = e.target.value;
                }

                setInputNum(e.target.value);
              }}
            />
            {isCalibration && !loggedUserShouldINRConvert(loggedInUser) && " $"}
            {loggedUserShouldINRConvert(loggedInUser) && (
              <>
                <span className="text-lg ml-2">= {usdValueRef.current}</span>
                <span>{" (USD)"}</span>
              </>
            )}
          </div>
          {!isCalibration && (
            <>
              <p className="mt-5">
                <span>Desired Rate: </span>
                <span className="font-bold">${desiredRate || "0"}</span>
                {loggedUserShouldINRConvert(loggedInUser) &&
                  ` USD (${baseToTargetCurrency(
                    desiredRate || 0,
                    CURRENCY_CONVERSION_CONFIG.exchangeFactor
                  )} INR)`}
              </p>
              <p>
                <span>Standard Rate w/ Markup : </span>
                <StandardMarkup desiredRate={desiredRate} />
              </p>
              <p>
                <span>Calculated Markup : </span>
                <CalculatedMarkup
                  desiredRate={desiredRate}
                  currentRate={usdValueRef.current}
                />
              </p>
            </>
          )}
        </div>
        <div className="w-full flex justify-between mt-4">
          <button
            className="mr-3 bg-blue rounded-md text-blue-700 font-bold w-32 p-1 whitespace-nowrap"
            onClick={() => setRate(true)}
          >
            {loading ? "setting rate..." : "Set Rate"}
          </button>{" "}
          <button
            className="ml-3 bg-red-300 rounded-md text-red-500 font-bold w-32 p-1 whitespace-nowrap"
            onClick={() => setRate(false)}
          >
            Void Rate
          </button>
        </div>
        {err && (
          <div className="text-red-400">
            Something went wrong, please try again
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CustomerRateModal;
