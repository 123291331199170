import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Globe } from "lucide-react";
import { Button } from "components/ui/button";
import { nanoid } from "nanoid";
import {
  formatSocialLinkText,
  getIconForSocialType,
  getSocialProfileUrl,
} from "components/UserCardList/helpers/userCard";
import SvgIcon from "components/base/SvgIcon";

function SocialDropDown({ row }) {
  const hasSocialLinks = row.original.socialLinks?.length > 0;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open social links menu</span>
          <Globe className="h-6 w-6 text-gray-500" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {hasSocialLinks ? (
          <>
            <DropdownMenuLabel>Social Links</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        ) : (
          <DropdownMenuLabel className="text-slate-500">
            No Social Links Found
          </DropdownMenuLabel>
        )}
        {row.original.socialLinks.map((s) => {
          return (
            <DropdownMenuItem key={nanoid()}>
              <a
                key={nanoid()}
                className="flex items-center gap-2"
                href={getSocialProfileUrl(s.type, s.value)}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon
                  type={getIconForSocialType(s.type)}
                  className="!fill-gray-600"
                />
                {formatSocialLinkText(s.type)}
              </a>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default SocialDropDown;
