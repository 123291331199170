import classNames from "classnames";
import { connectToggleRefinement } from "react-instantsearch-dom";

const CustomCheckbox = ({ currentRefinement, refine, label, count }) => {
  return (
    <label
      className={classNames(
        "flex items-center text-sm whitespace-nowrap ais-RefinementList-label",
        { "ais-RefinementList-item--selected": currentRefinement }
      )}
    >
      <input
        type="checkbox"
        className="ais-RefinementList-checkbox"
        checked={currentRefinement}
        onChange={() => refine(!currentRefinement)}
      />
      <span
        className={classNames("overflow-hidden text-ellipsis capitalize", {
          "font-bold": currentRefinement,
        })}
      >
        {label}
      </span>
      {count?.unchecked > 0 && (
        <span className="ml-2 px-1 border rounded-full bg-gray-100">
          {count.unchecked}
        </span>
      )}
    </label>
  );
};

const ConnectedToggle = connectToggleRefinement(CustomCheckbox);

export default function CustomCheckboxRefinement({ attribute, label, value }) {
  return <ConnectedToggle attribute={attribute} label={label} value={value} />;
}
