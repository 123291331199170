/**
 * Component that loads the cloudinary widget
 */
import React, { useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getCloudinarySignature } from "api/graphql/queries";
import SvgButton from "components/base/SvgButton";

/**
 * Widget to handle uploading files to Cloudinary CDN.
 *
 * @param {Object} props - Component props
 */
function FileCloudinaryWidget({ onUpload, ...props }) {
  let cloudinaryWidget = useRef();

  const context = {
    origin: window.location.origin,
  };

  const showUploadWidget = () => {
    cloudinaryWidget.current = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_RESUME,
        sources: ["local"],
        clientAllowedFormats:
          props.resourceType === "aspose"
            ? [
                "PDF",
                "doc",
                "docx",
                "docm",
                "dotx",
                "rtf",
                "txt",
                "xls",
                "xlsx",
                "xlsm",
                "pot",
                "potm",
                "potx",
                "pps",
                "ppsm",
                "pptx",
                "ppt",
                "pptm",
              ]
            : [],
        multiple: false,
        maxFileSize: 6291456,
        language: "en",
        publicId: props.fileName,
        context,
        prepareUploadParams: (cb, { public_id, upload_preset, context }) => {
          API.graphql(
            graphqlOperation(getCloudinarySignature, {
              publicId: public_id,
              preset: upload_preset,
              context,
            })
          )
            .then((response) => {
              cb(response.data.getCloudinarySignature);
            })
            .catch(() => {
              alert("Something Went Wrong, Please Try Again");
              cb({ cancel: true });
            });
        },
      },
      (err, result) => {
        if (err) {
          console.log("Error loading upload widget", err);
          if (err.statusText) {
            alert(err.statusText);
          }

          return;
        }

        if (result.info === "shown") {
          props.setLoadingWidget(false);
        }

        if (result.info === "hidden") {
          document.body.style.overflow = "visible";
        }

        if (result.event === "queues-end") {
          if (result.info?.files[0].status === "success") {
            const publicId = result.info?.files[0].uploadInfo.public_id;
            onUpload(publicId);
          } else {
            cloudinaryWidget.current.close();
          }
        }
        return;
      }
    );
    if (cloudinaryWidget.current) {
      cloudinaryWidget.current.open();
    }
  };

  useEffect(() => {
    if (props.openWidget && !cloudinaryWidget.current) {
      showUploadWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openWidget]);

  if (props.openWidget) {
    return null;
  }

  return (
    <div
      className="sm:flex  items-center gap-2 cursor-pointer mb-1"
      onClick={showUploadWidget}
    >
      <SvgButton icon={props.icon ?? "upload"} tooltip={props.tooltip} />
    </div>
  );
}

export default FileCloudinaryWidget;
