import Input from "components/base/Input";
import { SearchContext } from "context/providers";
import { debounce } from "lodash";
import { YEARS_OF_EXPERIENCE_FILTER_STR_TEMPLATE } from "lookup";
import React, { useContext, useEffect, useState } from "react";
import { Panel } from "react-instantsearch-dom";

const getValueFromFilterStr = (filterStr) => {
  return filterStr.replace(/[()]/g, "")?.split(" ")?.[4] || "";
};

const YearsOfExperience = () => {
  const { searchState, setSearchState } = useContext(SearchContext);

  const [inputValue, setInputValue] = useState();

  const onChangeInputDebounce = debounce(
    (value) => {
      setInputValue(inputValue);
      setSearchState({
        ...searchState,
        yearsOfExperienceFilterStr: value
          ? YEARS_OF_EXPERIENCE_FILTER_STR_TEMPLATE.replace("{!value}", value)
          : "",
      });
    },
    [500]
  );

  useEffect(() => {
    const value = getValueFromFilterStr(searchState.yearsOfExperienceFilterStr);

    setInputValue(value);
  }, [searchState.yearsOfExperienceFilterStr]);

  return (
    <div id="filter-group-years-of-experience">
      <Panel header="Years of Experience">
        <div className="flex gap-x-3">
          <Input
            value={inputValue}
            className="w-[75px] lg:w-[175px]"
            placeholder="Years of Experience"
            onChange={({ value }) => {
              onChangeInputDebounce(value);
            }}
            onKeyDown={(evt) => {
              ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault();
            }}
          />
          <p className="text-sm mt-2.5 whitespace-nowrap"> + years</p>
        </div>
      </Panel>
    </div>
  );
};

export default YearsOfExperience;
