import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import api from "apiSingleton";
import SvgIcon from "components/base/SvgIcon";
import {
  APPLICATION_TAB_NAMES,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_STATUS_FORMATTED,
  JOB_OPPORTUNITY_STATUSES,
} from "lookup";
import Collapsible from "components/Collapsible";
import { capitalize } from "lodash";
import dayjs from "dayjs";
import { Dialog, DialogTrigger } from "components/ui/dialog";
import UserInfoModalV2 from "containers/ModalContainer/modals/UserInfoModalv2";
import { InfoIcon } from "lucide-react";

const formatMatchJobStatus = (status) => {
  const formattedStatus = JOB_APPLICATION_MATCH_STATUS_FORMATTED[status];
  return formattedStatus ?? capitalize(status);
};

const compareDates = (a, b) => {
  const dateA = new Date(a.updatedAt || a.createdAt);
  const dateB = new Date(b.updatedAt || b.createdAt);
  return dateB - dateA;
};

const getJobName = (jobOpp) => {
  const title = jobOpp
    ? jobOpp.status === JOB_OPPORTUNITY_STATUSES.FULFILLED
      ? `${jobOpp.title} (${jobOpp.status?.toLowerCase()})`
      : jobOpp.title || "-"
    : "-";

  if (title === "-") {
    return <>{title}</>;
  }

  return (
    <Link
      to={`/jobOpps/${jobOpp.id}/details`}
      target="_blank"
      className="text-sky-500 cursor-pointer hover:underline"
      title={title}
    >
      {title}
    </Link>
  );
};

const isNotAutoShortlisted = (match) => {
  if (
    match.status === JOB_APPLICATION_MATCH_STATUS.SHORTLISTED &&
    match.reasonForAutoMatch?.startsWith("Auto shortlisted")
  ) {
    return false;
  }
  return true;
};

const formatMatches = (applications, matches) => {
  // Create a map for quick lookup of isNotActive status
  const applicationMap =
    applications?.reduce((acc, app) => {
      acc[app.id] = app.isNotActive || false;
      return acc;
    }, {}) || {};

  // Iterate over matches and add hasInactiveApplication property
  const formattedMatches =
    matches?.map((match) => {
      return {
        ...match,
        hasInactiveApplication: applicationMap[match.applicationId] === true,
      };
    }) || [];

  const nonSkippedNonAutoShortlistedMatches = formattedMatches.filter(
    (match) =>
      match.status !== JOB_APPLICATION_MATCH_STATUS.SKIPPED &&
      isNotAutoShortlisted(match)
  );

  return nonSkippedNonAutoShortlistedMatches.sort(compareDates);
};

const MatchItem = ({ match, showDivider, user }) => {
  const jobName = getJobName(match.jobOpportunity);

  return (
    <div
      className="flex flex-col font-rubik-medium text-sm w-full"
      key={match.id}
    >
      {showDivider && <hr className="border-t my-1 w-full" />}

      <div className="flex space-x-2.5">
        <span className="flex-1">{formatMatchJobStatus(match.status)}</span>

        <span className="flex-1">
          {dayjs(match.updatedAt).format("MM DD YYYY").split(" ").join("/")}
        </span>

        <span className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden">
          {jobName}
        </span>
        <span
          className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
          title={match.jobOpportunity?.organization}
        >
          {match.jobOpportunity?.organization}
        </span>
        <span
          className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
          title={match.rate?.value}
        >
          {match.rate?.value ? "$" + match.rate?.value : ""}
        </span>
        <span className="flex-1">
          {match.hasInactiveApplication && (
            <SvgIcon
              type="warning"
              className="w-5 h-5 cursor-help text-amber-600"
              title="This match is associated with a job role that is no longer active for this user"
            />
          )}
        </span>

        <Dialog>
          <DialogTrigger>
            <InfoIcon className="hover:scale-125 cursor-pointer" />
          </DialogTrigger>

          <UserInfoModalV2 match={match} user={user} />
        </Dialog>
      </div>
    </div>
  );
};

const MatchedJobs = ({ hit, activeTabName }) => {
  const [isLoading, setIsLoading] = useState(true);

  const userMatchedJobs = useRef([]);

  const handleToggleCollapse = async () => {
    if (activeTabName === APPLICATION_TAB_NAMES.TOPCANDIDATES) {
      return;
    } else if (!isLoading) {
      // The data has been loaded earlier. No need to fetch it again
      return;
    }

    let applications = await api.user.getAllApplicationsForUser({
      userId: hit.id,
    });

    let matches = await api.match.getMatchesForUser({
      userId: hit.id,
    });

    userMatchedJobs.current = formatMatches(applications, matches);

    setIsLoading(false);
  };

  useEffect(() => {
    if (activeTabName === APPLICATION_TAB_NAMES.TOPCANDIDATES) {
      userMatchedJobs.current = formatMatches(hit?.applications, hit?.matches);

      setIsLoading(false);
    }
  }, [activeTabName, hit?.applications, hit?.matches]);

  // Retaining existing behaviour - we don't show the accordion for Top Candidates tab if there are no matched jobs
  // For others, we are not yet sure because the user has to expand the accordion to trigger the api request
  // that will fetch (if any) the matches
  if (
    (activeTabName === APPLICATION_TAB_NAMES.TOPCANDIDATES &&
      userMatchedJobs.current?.length === 0) ||
    activeTabName === APPLICATION_TAB_NAMES.SKIPPED
  ) {
    return null;
  }

  return (
    <Collapsible
      className="talent-card-collapsible-section"
      label="Matched Jobs"
      onToggleCollapse={handleToggleCollapse}
    >
      {isLoading && <div className="text-gray-400">Loading...</div>}
      {!isLoading && (
        <>
          {userMatchedJobs.current?.length === 0 && (
            <div>There are no matched jobs for this user</div>
          )}
          {userMatchedJobs.current?.map((match, index) => (
            <MatchItem
              key={index}
              match={match}
              showDivider={index > 0}
              user={hit}
            />
          ))}
        </>
      )}
    </Collapsible>
  );
};

MatchedJobs.propTypes = {
  hit: PropTypes.object,
  activeTabName: PropTypes.string,
};

MatchedJobs.defaultProps = {
  hit: {},
  activeTabName: APPLICATION_TAB_NAMES.TOPCANDIDATES,
};

export default MatchedJobs;
