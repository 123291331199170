import { useContext, useMemo } from "react";
import { AuthContext, JobsContext } from "context/providers";
import { CompatRoute } from "react-router-dom-v5-compat";
import { Redirect } from "react-router-dom";
import { getUserName } from "utils/helpers/users";
import Header from "components/Header";

export const PrivateRoute = ({
  component: Component,
  signOut,
  isAssistantRoute,
  ...rest
}) => {
  const { user } = useContext(AuthContext);
  const { jobOpp } = useContext(JobsContext);

  const loading = useMemo(() => !user, [user]);

  if (loading) {
    return (
      <>
        <Header signOut={signOut} user={user} jobOpp={jobOpp} />
        <div className="flex justify-center mt-8">
          <span className="loader"></span>
        </div>
      </>
    );
  }

  const shouldRedirectAssistant =
    (isAssistantRoute && !user.canAccessJobAssistant) ||
    (rest.path === "/talent-creation" &&
      !user.canAccessResumeProfileTalentCreation);

  if (shouldRedirectAssistant) {
    return <Redirect to="/" />;
  }

  return (
    <CompatRoute
      key={rest.computedMatch.url}
      {...rest}
      render={(props) =>
        user.canAccessApplication ? (
          <>
            <Header
              {...props}
              signOut={signOut}
              user={user}
              jobOpp={jobOpp}
              isAuthorized={user.canAccessApplication}
            />
            {<Component user={user} />}
          </>
        ) : (
          <>
            <Header
              {...props}
              signOut={signOut}
              user={user}
              jobOpp={jobOpp}
              isAuthorized={user.canAccessApplication}
            />
            <div className="text-center pt-8">
              <p className="text-lg font-semibold text-gray-500">
                @{getUserName(user)}{" "}
                <span className="text-red-400">
                  You are not authorized to use this service. Please contact
                  administrator if you think this is a mistake.
                </span>
              </p>
            </div>
          </>
        )
      }
    />
  );
};
