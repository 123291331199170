import React, {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Select from "react-select";

import classNames from "classnames";

import { AlertContext, AuthContext, JobsContext } from "context/providers";

import SvgButton from "components/base/SvgButton";
import JobListingCard from "components/JobListingCard";

import { JOB_OPPORTUNITY_STATUSES } from "lookup";
import { Autocomplete } from "@aws-amplify/ui-react";

const PageButton = ({ symbol, goToPage, disabled }) => {
  return (
    <button
      className="border px-2 bg-gray-50 rounded"
      onClick={goToPage}
      disabled={disabled}
    >
      {symbol}
    </button>
  );
};

const PaginationButtons = ({ goToPage, currentPage, totalPages }) => {
  return (
    <div className="flex justify-end mt-2 items-center">
      <PageButton
        symbol={"<<"}
        goToPage={() => goToPage(1)}
        disabled={currentPage === 1}
      />
      <PageButton
        symbol={"<"}
        goToPage={() => goToPage(currentPage - 1)}
        disabled={currentPage === 1}
      />
      <span className="mx-2">
        Page {currentPage} of {totalPages}
      </span>
      <PageButton
        symbol={">"}
        goToPage={() => goToPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <PageButton
        symbol={">>"}
        goToPage={() => goToPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    </div>
  );
};

const PaginatedList = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 50;

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data.slice(startIndex, startIndex + itemsPerPage);

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    // data has change start from page 1
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  if (data?.length === 0) {
    return null;
  }

  return (
    <>
      <PaginationButtons
        goToPage={goToPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <div className="flex flex-col gap-y-8">
        {currentItems.map((item) => (
          <JobListingCard key={item.id} legacyJob={item} />
        ))}
      </div>
      <PaginationButtons
        goToPage={goToPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </>
  );
};

export default function JobOpportunities() {
  const {
    jobs,
    isLoading,
    companyFilter,
    companyNames,
    jobTypeFilter,
    jobTypes,
    jobSkillsFilter,
    jobOptionalSkillsFilter,
    statusFilter,
    jobSkills,
    jobOptionalSkills,
    updateCompanyFilter,
    updateJobTypeFilter,
    updateStatusFilter,
    updateJobSkillsFilter,
    updateJobOptionalSkillsFilter,
    updateTitleSearchFilter,
    init,
    torcOwnersFilter,
    torcOwners,
    updateTorcOwnerFilter,
    titleSearchFilter,
    customerOwnerFilter,
    jobIdFilter,
    updatedCustomerOwnerFilter,
    geographicalRegionFilter,
    updateGeographicalRegionFilter,
    updateJobIdFilter,
  } = useContext(JobsContext);
  const { clearAlerts } = useContext(AlertContext);

  const { user } = useContext(AuthContext);

  const showCustomerFilter = useMemo(() => {
    return !user.company;
  }, [user.company]);

  const onCompanyChange = useCallback(
    (value) => {
      updateCompanyFilter(value);
    },
    [updateCompanyFilter]
  );

  const onJobTypeChange = useCallback(
    (value) => {
      updateJobTypeFilter(value);
    },
    [updateJobTypeFilter]
  );

  const onJobSkillsChange = useCallback(
    (value) => {
      updateJobSkillsFilter(value);
    },
    [updateJobSkillsFilter]
  );

  const onJobOptionalSkillsChange = useCallback(
    (value) => {
      updateJobOptionalSkillsFilter(value);
    },
    [updateJobOptionalSkillsFilter]
  );

  const onStatusChange = useCallback(
    (value) => {
      updateStatusFilter(value);
    },
    [updateStatusFilter]
  );

  const onTorcOwnerChange = useCallback(
    (value) => {
      updateTorcOwnerFilter(value);
    },
    [updateTorcOwnerFilter]
  );

  const onTitleSearch = useCallback(
    (value) => {
      updateTitleSearchFilter(value.toLowerCase());
    },
    [updateTitleSearchFilter]
  );

  const onCustomerOwnerChange = useCallback(
    (value) => {
      updatedCustomerOwnerFilter(value);
    },
    [updatedCustomerOwnerFilter]
  );

  const onGeographicalRegionFilterChange = useCallback(
    (value) => {
      if (geographicalRegionFilter.includes(value)) {
        updateGeographicalRegionFilter(
          geographicalRegionFilter.filter((item) => item !== value)
        );
      } else {
        updateGeographicalRegionFilter([...geographicalRegionFilter, value]);
      }
    },
    [updateGeographicalRegionFilter, geographicalRegionFilter]
  );

  const onJobIdChange = useCallback(
    (value) => {
      updateJobIdFilter(value);
    },
    [updateJobIdFilter]
  );

  const handleRefresh = () => {
    init();
  };

  const jobsSearchData = useMemo(
    () =>
      jobs?.reduce(
        (acc, job) => {
          acc.titleSearch.push({ id: job.id ?? "", label: job.title ?? "" });

          if (job?.geographicalRegions) {
            job.geographicalRegions?.forEach((item) => {
              if (!acc.geographicalRegions[item.regionName]) {
                acc.geographicalRegions[item.regionName] = true;
              }
            });
          }

          if (
            job?.customerOwner?.username &&
            !acc.customerOwners[job.customerOwner.username]
          ) {
            acc.customerOwners[job.customerOwner.username] = true;
          }
          return acc;
        },
        {
          titleSearch: [],
          geographicalRegions: {},
          customerOwners: {},
        }
      ),

    [jobs]
  );

  const hasPassedTorcOwnerFilterTest = (torcOwner) => {
    if (torcOwnersFilter === "All") {
      return true;
    }
    if (torcOwnersFilter === "None" && !torcOwner) {
      return true;
    }
    if (torcOwnersFilter === torcOwner?.id) {
      return true;
    }

    return false;
  };

  const getTorcOwnerName = () => {
    const torcOwner = torcOwners.find((owner) => owner.id === torcOwnersFilter);
    return torcOwner?.username || "All";
  };

  const getFilteredJobs = () => {
    if (
      companyFilter === "All" &&
      jobTypeFilter === "All" &&
      jobSkillsFilter.includes("All") &&
      jobOptionalSkillsFilter.includes("All") &&
      statusFilter.includes("All") &&
      torcOwnersFilter === "All" &&
      customerOwnerFilter === "All" &&
      titleSearchFilter === "" &&
      geographicalRegionFilter.length === 0
    ) {
      return jobs.map((job) => job).filter((e) => e);
    }

    return jobs
      .map((job) => {
        const organizationName = job?.organization;

        const jobSkillsValidation =
          jobSkillsFilter.includes("All") ||
          (job.skills?.some((skill) => jobSkillsFilter.includes(skill.name)) ??
            false);

        const jobOptionalSkillsValidation =
          jobOptionalSkillsFilter.includes("All") ||
          (job.optionalSkills?.some((skill) =>
            jobOptionalSkillsFilter.includes(skill.name)
          ) ??
            false);

        if (
          (organizationName === companyFilter || companyFilter === "All") &&
          (job?.jobType?.title === jobTypeFilter || jobTypeFilter === "All") &&
          jobSkillsValidation &&
          jobOptionalSkillsValidation &&
          (statusFilter.includes(job?.status) ||
            statusFilter.includes("All")) &&
          hasPassedTorcOwnerFilterTest(job?.torcOwner) &&
          (job?.id === titleSearchFilter ||
            (job?.title || "")?.toLowerCase()?.includes(titleSearchFilter)) &&
          (customerOwnerFilter === "All" ||
            job.customerOwner?.username === customerOwnerFilter) &&
          (!jobIdFilter ||
            jobIdFilter === job.externalJobId ||
            jobIdFilter === job.id) &&
          (geographicalRegionFilter.length === 0 ||
            job.geographicalRegions?.find((regions) =>
              geographicalRegionFilter.includes(regions.regionName)
            ))
        ) {
          return job;
        }

        return null;
      })
      .filter((e) => e);
  };

  useEffect(() => {
    clearAlerts();
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set default torc owner filter to current user on page load
  useEffect(() => {
    // if there is already a value set do not override with self as torc-owner
    if (torcOwnersFilter || isLoading || !user.id) {
      return;
    }

    if (torcOwners.length > 0) {
      const self = torcOwners.find((tc) => tc.id === user.id);

      // If we are not found as a Torc Owner, don't touch the default (All)
      if (self) {
        onTorcOwnerChange(self.id);
      } else {
        onTorcOwnerChange("All");
      }
    } else {
      onTorcOwnerChange("All");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [torcOwners, isLoading, user]);

  const options = [
    ...Object.values(JOB_OPPORTUNITY_STATUSES).map((status) => ({
      value: status,
      label: status,
    })),
  ];

  const skillsOptions = [
    { value: "All", label: "All" },
    ...jobSkills.map((e) => ({
      value: e,
      label: e,
    })),
  ];

  const optionalSkillsOptions = [
    { value: "All", label: "All" },
    ...jobOptionalSkills.map((e) => ({
      value: e,
      label: e,
    })),
  ];

  const renderRequiredSkillsFilter = () => {
    return (
      <div className="w-full">
        <label className="mr-2 font-bold">Required Skills</label>
        <div className="block mt-2">
          <Select
            value={skillsOptions.filter((option) =>
              jobSkillsFilter?.includes(option.value)
            )}
            options={skillsOptions}
            isMulti
            className="block mt-2 border rounded border-black w-full p-0 md:p-1"
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions
                ? selectedOptions.map((option) => option.value)
                : [];

              onJobSkillsChange(selectedValues);
            }}
            styles={{
              valueContainer: (styles) => ({
                ...styles,
                overflow: undefined,
                maxHeight: "175px",
                overflowX: "auto",
              }),
            }}
          />
        </div>
      </div>
    );
  };

  const renderRequiredOptionalSkillsFilter = () => {
    return (
      <div className="w-full">
        <label className="mr-2 font-bold">Optional Skills</label>
        <div className="block mt-2">
          <Select
            value={optionalSkillsOptions.filter((option) =>
              jobOptionalSkillsFilter?.includes(option.value)
            )}
            options={optionalSkillsOptions}
            isMulti
            className="block mt-2 border rounded border-black w-full p-0 md:p-1"
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions
                ? selectedOptions.map((option) => option.value)
                : [];

              onJobOptionalSkillsChange(selectedValues);
            }}
            styles={{
              valueContainer: (styles) => ({
                ...styles,
                overflow: undefined,
                maxHeight: "175px",
                overflowX: "auto",
              }),
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="max-w-3xl mx-auto my-12 flex flex-col gap-8 px-4">
        <div className="w-full flex justify-center items-center">
          <h3 className="text-center text-2xl font-bold mr-2">Job Search</h3>

          <SvgButton
            icon="refresh"
            className={classNames("w-[20px] transform rotate-180", {
              "animate-spin": isLoading,
              "animate-none": !isLoading,
            })}
            title="Refresh Jobs List"
            onClick={handleRefresh}
          />
        </div>
        <div className="flex justify-between gap-2">
          {showCustomerFilter && (
            <div className="w-full">
              <label className="mr-2 font-bold whitespace-nowrap">
                Customer Name
              </label>
              <select
                value={companyFilter}
                className="block mt-2 border rounded border-black w-full px-1 py-[9px]"
                onChange={(e) => onCompanyChange(e.target.value)}
              >
                <option value="All">All</option>
                {companyNames.map((name) => (
                  <option value={name} key={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="w-full">
            <label className="font-bold">Job Role</label>
            <div className="block mt-2">
              <select
                value={jobTypeFilter}
                className="block mt-2 border rounded border-black w-full px-1 py-[9px]"
                onChange={(e) => onJobTypeChange(e.target.value)}
              >
                <option value="All">All</option>
                {jobTypes.map((jobType, i) => (
                  <option value={jobType} key={`jobtype=${i}`}>
                    {jobType}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full">
            <label className="mr-2 font-bold whitespace-nowrap">Matcher</label>
            <Autocomplete
              placeholder={torcOwnersFilter ? getTorcOwnerName() : "All"}
              options={[
                { id: "All", label: "All" },
                ...torcOwners.map((item) => ({
                  id: item?.id ?? "",
                  label: item?.username,
                })),
              ]}
              onSelect={({ id }) => onTorcOwnerChange(id)}
              onClear={() => onTorcOwnerChange("All")}
              className="mt-2"
              menuSlots={{
                Empty: <p>No results found</p>,
              }}
            />
          </div>
        </div>
        <div className="flex gap-2">
          {renderRequiredSkillsFilter()}
          {renderRequiredOptionalSkillsFilter()}
        </div>
        <div className="flex gap-2">
          <div className="w-full">
            <label className="font-bold">Search</label>
            <Autocomplete
              label="search"
              placeholder="Search by job title"
              options={jobsSearchData.titleSearch}
              value={titleSearchFilter}
              onChange={(e) => onTitleSearch(e.target.value)}
              onSelect={({ label }) =>
                onTitleSearch(label?.toLowerCase() ?? "")
              }
              onClear={() => onTitleSearch("")}
              className="mt-2 w-full"
              menuSlots={{
                Empty: <p>No results found</p>,
              }}
            />
          </div>
          <div className="w-full">
            <label className="font-bold whitespace-nowrap">
              Customer Owner
            </label>
            <Autocomplete
              label="Customer Owner"
              placeholder="All"
              options={Object.keys(jobsSearchData.customerOwners ?? {}).map(
                (owner) => ({ id: owner, label: owner })
              )}
              onSelect={({ label }) => onCustomerOwnerChange(label)}
              onClear={() => onCustomerOwnerChange("All")}
              className="mt-2 w-full"
              menuSlots={{
                Empty: <p>No results found</p>,
              }}
            />
          </div>
          <div className="w-full">
            <label className="mr-2 font-bold whitespace-nowrap">
              ID / Bullhorn ID
            </label>
            <input
              type="text"
              value={jobIdFilter}
              className="block mt-2 border rounded border-black w-full px-1 py-[7.5px]"
              onChange={(e) => onJobIdChange(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-3">
          {Object.keys(jobsSearchData.geographicalRegions).map((geo) => {
            const isSelected = geographicalRegionFilter.includes(geo);
            return (
              <button
                key={geo}
                onClick={() => onGeographicalRegionFilterChange(geo)}
                className={`py-1 px-2 font flex gap-1 items-center text-sm hover:cursor-pointer hover:bg-blue-500 hover:text-white group font-nexa-regular rounded ${
                  isSelected ? "bg-blue-500" : "bg-gray-100"
                } ${isSelected ? "text-white" : "text-gray-500"}`}
              >
                {geo}
              </button>
            );
          })}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <div className="max-w-5xl">
            <label className="font-bold">Status</label>
            <div className="block mt-2">
              <Select
                value={options.filter((option) =>
                  statusFilter.includes(option.value)
                )}
                options={options}
                isMulti
                className="block mt-2 border rounded border-black w-full p-0 md:p-1"
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  onStatusChange(selectedValues);
                }}
              />
            </div>
          </div>
        </div>
        {isLoading && jobSkills?.length === 0 ? (
          <div className="flex justify-center mt-8">
            <span className="loader"></span>
          </div>
        ) : (
          <PaginatedList data={getFilteredJobs()} />
        )}
      </div>
    </div>
  );
}
