import { cloneDeep } from "lodash";
import { LANGUAGE_KEY_NAMES, SOCIAL_LINK_TYPES } from "lookup";
import api from "apiSingleton";

const ALGOLIA_RESPONSE_CONFIG = JSON.parse(
  process.env.REACT_APP_ALGOLIA_RESPONSE_CONFIG || "{}"
);

const algoliaResponsesCache = new Map();
const algoliaRequestsCache = new Map();

const prepareLangs = (languages = undefined, languageLevels = undefined) => {
  const output = [];

  switch (true) {
    case !!languages && !!languageLevels: {
      languages.forEach((lang) => {
        languageLevels.forEach((level) => {
          output.push(`knownLanguages_str:${lang}_${level}`);
        });
      });

      break;
    }

    case !!languages: {
      languages.forEach((lang) => {
        output.push(`knownLanguages.language:${lang}`);
      });

      break;
    }

    case !!languageLevels: {
      languageLevels.forEach((level) => {
        output.push(`knownLanguages.level:${level}`);
      });

      break;
    }

    default: {
      break;
    }
  }

  return output;
};

const getIsStateApplied = (state) => {
  let isMultirangeApplied = false;
  let isToggleApplied = false;
  let isRefinementListApplied = false;
  let isRangeApplied = false;

  if (state.multiRange) {
    isMultirangeApplied = !!Object.values(state.multiRange).filter(
      (el) => !!el.length
    ).length;
  }

  if (state.toggle) {
    isToggleApplied = !!Object.values(state.toggle).length;
  }

  if (state.refinementList) {
    isRefinementListApplied = !!Object.values(state.refinementList).filter(
      (el) => !!el.length
    ).length;
  }

  if (state.range) {
    isRangeApplied = !!Object.values(state.range).filter(
      (el) => !!el.min || !!el.max
    ).length;
  }

  return (
    isMultirangeApplied ||
    isToggleApplied ||
    isRefinementListApplied ||
    isRangeApplied
  );
};

export const prepareQuery = (state, jobTypeTitle) => {
  const filter = {};
  const isStateApplied = getIsStateApplied(state.searchState);

  if (!isStateApplied) {
    // Default state
    const finalFacetFilters = ["agreedToTerms:false"];

    if (jobTypeTitle) {
      if (state.AISkillSearchConfig && !state.AISkillSearchConfig.enabled) {
        finalFacetFilters.push(`activeJobTypeTitles:${jobTypeTitle}`);
      }
    }
    return {
      facetFilters: finalFacetFilters,
    };
  }

  if (state.searchState.multiRange) {
    filter.numericFilters = [];

    Object.keys(state.searchState.multiRange).forEach((key) => {
      const [min, max] = state.searchState.multiRange[key].split(":");

      if (min && max) {
        filter.numericFilters = [
          ...filter.numericFilters,
          `${key}:${min} TO ${max}`,
        ];
      } else if (min && !max) {
        filter.numericFilters = [...filter.numericFilters, `${key}>=${min}`];
      } else if (!min && max) {
        filter.numericFilters = [...filter.numericFilters, `${key}<=${max}`];
      }
    });
  }

  if (state.searchState.refinementList || state.searchState.toggle) {
    const filters = {
      ...(state.searchState.refinementList || {}),
      ...(state.searchState.toggle || {}),
    };

    const isThereLangFilters =
      filters[LANGUAGE_KEY_NAMES.language] || filters[LANGUAGE_KEY_NAMES.level];

    const filtersKeys = Object.keys(filters).filter(
      (key) =>
        key !== LANGUAGE_KEY_NAMES.language && key !== LANGUAGE_KEY_NAMES.level
    );

    if (!filter.facetFilters) {
      filter.facetFilters = [];
    }

    if (isThereLangFilters) {
      filter.facetFilters = [
        ...filter.facetFilters,
        prepareLangs(
          filters[LANGUAGE_KEY_NAMES.language],
          filters[LANGUAGE_KEY_NAMES.level]
        ),
      ];
    }

    filtersKeys.forEach((key) => {
      if (typeof filters[key] === "boolean" && !!filters[key]) {
        filter.facetFilters = [
          ...filter.facetFilters,
          `${key}:${filters[key]}`,
        ];
      } else if (!!filters[key]) {
        const operator = state.operators[key];
        const currentFilter =
          operator && operator === "and"
            ? filters[key].map((el) => `${key}:${el}`)
            : [filters[key].map((el) => `${key}:${el}`)];

        filter.facetFilters = [...filter.facetFilters, ...currentFilter];
      }
    });
  }

  if (state.searchState.range) {
    filter.numericFilters = [...(filter.numericFilters || [])];

    Object.keys(state.searchState.range).forEach((key) => {
      const { min, max } = state.searchState.range[key];

      filter.numericFilters = [
        ...filter.numericFilters,
        `${key}:${min || 0} TO ${max || 0}`,
      ];
    });
  }

  if (!state.searchState.toggle) {
    if (!filter.facetFilters) {
      filter.facetFilters = [];
    }

    filter.facetFilters.push(`agreedToTerms:false`);
  }

  if (jobTypeTitle) {
    if (!filter.facetFilters) {
      filter.facetFilters = [];
    }

    if (state.AISkillSearchConfig && !state.AISkillSearchConfig.enabled) {
      filter.facetFilters.push(`activeJobTypeTitles:${jobTypeTitle}`);
    }
  }

  return filter;
};

const mapHit = (hit, matches) => {
  const clonedHit = cloneDeep(hit);
  const associatedMatch = matches.find((m) => m.userId === hit.id);

  clonedHit.associatedMatch = associatedMatch;
  clonedHit.socialLinks = clonedHit.socialLinks
    ? clonedHit.socialLinks.filter(
        (s) => !!s.value && s.type !== SOCIAL_LINK_TYPES.CALENDAR
      )
    : [];

  return {
    ...clonedHit,
  };
};

export const mapHits = (hits, matches, filterOutHitByMatchStatus = []) => {
  const mappedHits = hits
    .map((hit) => mapHit(hit, matches))
    .filter(
      (hit) =>
        !!hit &&
        !filterOutHitByMatchStatus.includes(hit.associatedMatch?.status)
    );

  return mappedHits;
};

// Default ttl cache 1 minute
const CACHE_TTL =
  (ALGOLIA_RESPONSE_CONFIG.responseCacheTTLMin ?? 1) * 60 * 1000;

export const customSearchClient = {
  async search(requests) {
    if (ALGOLIA_RESPONSE_CONFIG.cacheIsDisabled) {
      const results = await api.proxy.searchAlgolia({
        requests: JSON.stringify(requests),
      });

      return JSON.parse(results.data.searchViaAlgolia);
    }

    const requestsKey = JSON.stringify(requests);

    if (algoliaResponsesCache.has(requestsKey)) {
      const { data, expiryDate } = algoliaResponsesCache.get(requestsKey);
      if (Date.now() < expiryDate) {
        return data;
      } else {
        console.log("Expired making requests");

        // Remove expired cache.
        algoliaResponsesCache.delete(requestsKey);
      }
    }

    if (algoliaRequestsCache.has(requestsKey)) {
      return algoliaRequestsCache.get(requestsKey);
    }

    // Create a promise to perform the API call.
    const searchPromise = (async () => {
      try {
        const results = await api.proxy.searchAlgolia({
          requests: JSON.stringify(requests),
        });
        const parsedResults = JSON.parse(results.data.searchViaAlgolia);

        const isPlatformUserIndex = requestsKey.includes(
          process.env.REACT_APP_INDEX_NAME
        );

        if (isPlatformUserIndex) {
          // Cache the result along with its expiryDate.
          algoliaResponsesCache.set(requestsKey, {
            data: parsedResults,
            expiryDate: Date.now() + CACHE_TTL,
          });
        }
        return parsedResults;
      } finally {
        // Remove the in-progress request once done.
        algoliaRequestsCache.delete(requestsKey);
      }
    })();

    // Store/cache the in-progress request.
    algoliaRequestsCache.set(requestsKey, searchPromise);

    return searchPromise;
  },

  async searchForFacetValues(requests) {
    const results = await api.proxy.searchAlgolia({
      requests: JSON.stringify(requests),
      sffv: true,
    });

    return JSON.parse(results.data.searchViaAlgolia);
  },
};
