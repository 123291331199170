import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useContext, useMemo } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { prepareLocation } from "components/UserCardList/helpers/userCard";
import { APPLICATION_TAB_NAMES } from "lookup";
import { MatchContext, SearchContext, JobsContext } from "context/providers";
import ResumeUpload from "../UserCardResult/molecules/Resume";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import UserCardResult from "../UserCardResult";
import { Button } from "components/ui/button";
import { DataTablePagination } from "./Pagination";
import { cn } from "utils";
import MatchActionDropDown from "./MatchActionDropDown";
import SocialDropDown from "./SocialsDropDown";
import { getUserName } from "utils/helpers/users";
import HitActionDropDown from "./HitActionDropDown";

const prepareItemsForTable = (items) => {
  const preparedItems = items?.map((item) => {
    return {
      ...item,
      desiredRate: prepareRate(item),
      location: prepareLocation(item.location),
    };
  });
  return preparedItems || [];
};

const ResumeCell = ({ row }) => {
  const {
    updateMatchUserFieldLocally,
    matches,
    updateFitFieldLocally,
    bestFit,
  } = useContext(MatchContext);

  const hit = row.original;

  const { updateHit } = useContext(SearchContext);

  const saveAttributes = async (attributes) => {
    const attributesToUpdate = {
      input: {
        id: hit.id,
        ...attributes,
      },
    };
    try {
      await updateHit(attributesToUpdate);

      if (bestFit.find(({ id }) => id === hit.id)) {
        updateFitFieldLocally(
          hit.id,
          "resumeLocation",
          attributes.resumeLocation
        );
      }

      if (matches.length > 0) {
        const applicationId =
          hit.match?.applicationId ||
          matches.find(({ userId }) => userId === hit.id)?.applicationId;

        if (!applicationId) {
          return;
        }

        updateMatchUserFieldLocally(
          applicationId,
          "resumeLocation",
          attributes.resumeLocation
        );
      }
    } catch (error) {
      console.log("Error when updating profile info", error);
    }
  };

  return <ResumeUpload user={row.original} save={saveAttributes} />;
};

const SkillsCell = ({ row }) => {
  const { jobOpp } = useContext(JobsContext);
  const { skills } = row.original;

  if (!Array.isArray(skills)) return null;

  const talentSkillNames = new Set(
    skills?.map((talentSkill) => talentSkill.name)
  );
  const sameSkills = jobOpp.skills?.filter((skill) =>
    talentSkillNames.has(skill.name)
  );
  return (
    <div>
      {sameSkills.length} out of {jobOpp.skills.length}
    </div>
  );
};

const RateToCurrency = ({ rate, type }) => {
  const rateString = `${rate.toLocaleString()}`;
  const typeLabelMapping = {
    salary: "Salary",
    hourly: "Hourly Rate",
  };
  return (
    <p>
      {typeLabelMapping[type]}: <span className="font-bold">{rateString}</span>
    </p>
  );
};

const prepareRate = (item) => {
  const notDefinedText = "Rate Unknown";

  if (!!item.match?.rate?.value) {
    return <RateToCurrency type="hourly" rate={item.match.rate.value} />;
  }

  if (!!item.ratePerHour?.value) {
    return <RateToCurrency type="hourly" rate={item.ratePerHour.value} />;
  }

  if (!!item.salary?.value) {
    return <RateToCurrency type="salary" rate={item.salary.value} />;
  }

  return <p>{notDefinedText}</p>;
};

const UserTableListView = ({ items, activeTabName, collectionKey }) => {
  const itemsPreparedForTable = useMemo(
    () => prepareItemsForTable(items),
    [items]
  );

  const tabUsesMatches =
    activeTabName === APPLICATION_TAB_NAMES.SHORTLISTED ||
    activeTabName === APPLICATION_TAB_NAMES.MATCHED;

  const columns = useMemo(() => {
    const columnList = [
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => {
          const username = getUserName(row.original);

          return (
            <div className="w-[150px]">
              <p className="font-semibold">
                {row.original.given_name} {row.original.family_name}
              </p>
              <p className="flex">
                <a
                  className="overflow-hidden text-ellipsis transition-all text-sky-500 font-bold opacity-70 hover:opacity-100 hover:underline"
                  href={`${process.env.REACT_APP_DOMAIN}/#/profile/${username}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  @{username}
                </a>
              </p>
            </div>
          );
        },
      },
      {
        id: "location",
        header: "Location",
        cell: ({ row }) => {
          return <div className="w-[150px]">{row.original.location}</div>;
        },
      },
      {
        id: "rate",
        header: "Rate/Salary",
        cell: ({ row }) => {
          return (
            <div className="whitespace-nowrap">{row.original.desiredRate}</div>
          );
        },
      },
      {
        id: "skills",
        header: "Skills",
        cell: ({ row }) => {
          return <SkillsCell row={row} />;
        },
      },
      ...(tabUsesMatches
        ? [
            {
              id: "matchStatus",
              header: "Status",
              cell: ({ row }) => {
                return (
                  <div className="whitespace-nowrap">
                    {row.original.match.status}
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: "viewFullProfile",
        cell: ({ row }) => {
          return (
            <Dialog>
              <DialogTrigger>
                <Button
                  variant="outline"
                  size="sm"
                  className="border-black border-2"
                >
                  View Full Profile
                </Button>
              </DialogTrigger>
              <DialogContent className="overflow-y-scroll max-h-screen w-full max-w-[900px]">
                <UserCardResult
                  activeTabName={activeTabName}
                  hit={row.original}
                  showActions
                  showStatusColor
                />
              </DialogContent>
            </Dialog>
          );
        },
      },
      {
        id: "socialLinks",
        cell: ({ row }) => {
          return <SocialDropDown row={row} />;
        },
      },
      {
        id: "resume",
        cell: ({ row }) => {
          return <ResumeCell row={row} />;
        },
      },
    ];

    if (tabUsesMatches) {
      columnList.push({
        id: "matchActions",
        cell: ({ row }) => {
          return (
            <MatchActionDropDown
              row={row}
              activeTabName={activeTabName}
              collectionKey={collectionKey}
            />
          );
        },
      });
    } else {
      columnList.push({
        id: "hitActions",
        cell: ({ row }) => {
          return <HitActionDropDown row={row} collectionKey={collectionKey} />;
        },
      });
    }

    return columnList;
  }, [activeTabName, collectionKey, tabUsesMatches]);

  const table = useReactTable({
    data: itemsPreparedForTable,
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        finalist: activeTabName === APPLICATION_TAB_NAMES.MATCHED,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false,
  });

  return (
    <div className="pb-4">
      <Table className="border-separate border-spacing-y-2">
        <TableHeader>
          {table.getHeaderGroups()?.map((headerGroup) => {
            const { headers, id } = headerGroup;
            return (
              <TableRow key={id}>
                {headers.map((header, i) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn(
                        "whitespace-nowrap bg-white text-black text-base font-semibold font-rubik"
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows?.map((row) => {
              const visibleCells = row.getVisibleCells();

              return (
                <TableRow
                  key={row.id}
                  id={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="user-list-view-row bg-white text-black font-rubik"
                >
                  {visibleCells?.map((cell, i) => {
                    return (
                      <TableCell
                        key={cell.id}
                        id={cell.id}
                        className={"whitespace-normal user-list-view-cell"}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {tabUsesMatches ? <DataTablePagination table={table} /> : null}
    </div>
  );
};

export default UserTableListView;
