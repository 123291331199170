import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";

import HelpText from "components/HelpText";

import "./index.css";
import classNames from "classnames";

const CustomToggle = ({
  checked,
  valueKey,
  leftLabel,
  rightLabel,
  helpText,
  onChange,
  toolTipClassName,
  className = "",
}) => {
  const handleChange = ({ target: { checked } }) => {
    onChange(checked, valueKey);
  };

  return (
    <div className={classNames("flex gap-2", className)}>
      {leftLabel && <p>{leftLabel}</p>}

      <Toggle
        checked={checked}
        onChange={handleChange}
        icons={false}
        className="customToggle"
      />

      {rightLabel && <p>{rightLabel}</p>}

      {helpText && (
        <HelpText text={helpText} toolTipClassName={toolTipClassName} />
      )}
    </div>
  );
};

CustomToggle.propTypes = {
  checked: PropTypes.bool,
  valueKey: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  helpText: PropTypes.string,
  onChange: PropTypes.func,
};

CustomToggle.defaultProps = {
  checked: true,
  valueKey: "",
  leftLabel: "",
  rightLabel: "",
  helpText: "",
  onChange: () => {},
};

export default CustomToggle;
